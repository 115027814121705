import React, { useEffect, useState } from 'react'

function ErrorUp({message, removeMessage}) {
  const [display, setDisplay] = useState('flex')
  const [error, setError] = useState(null)


  useEffect(() =>{
    setError(message) 
    const timeout = setTimeout(()=>{
        setDisplay('none')
        removeMessage(null)
    },2500)
    return ()=> clearTimeout(timeout)
  },[message])

  return (
    <div className='message' style={{display : display, backgroundColor : '#f5a3a3', color : 'red'}}>
       <h1 className='h6'>{error}</h1>
    </div>
  )
}

export default ErrorUp;