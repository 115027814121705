import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Loading, NotAuthorized, NoData, NoConnection, SuccessUp, ErrorUp, catchError, url, Sloading, DiscountIcon, getToken, errorName, message, CustomWeightIcon, CrossIcon, fallbackImg } from '../../base/index'
import { Reviews } from '../../components/index'
import { changeTotalItem, dropdown } from '../../redux/UserInfoSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

function DetailProduct() {
    const { product } = useParams()
    const [data, setData] = useState(null)
    const [custom, setCustom] = useState(false)
    const [customQuantity, setCustomQuantity] = useState('')
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [isAdding, setIsAdding] = useState(false)
    const [totalReview, setTotalReview] = useState(0)
    const [avgRating, setAvgRating] = useState(0)
    const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
    const is_dukandar = useSelector((state) => state.userInfo.is_dukandar)
    const [imgSrc, setImgSrc] = useState(fallbackImg)
    const dispatch = useDispatch()
    const path = window.location.pathname

    async function add(button) {
        if (button === 'add' && (quantity <= 0 || quantity === ''))
            setError('Enter qunatity!')
        else if (button === 'add' && quantity > 30)
            setError('Quantity can not be greater then 30')
        else if (button === 'update' && customQuantity.trim() === '') 
            setError('Enter your custom quantity !')
        else {
            setCustom(false)
            try {
                setIsAdding(true)
                const token = getToken()
                const request = await axios.post(`${url}/api/cartItem/`, { 'product': data.id, 'quantity': quantity, 'custom_quantity': customQuantity, 'button': button },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                if (request.data.error_message) {
                    setError(request.data.error_message)
                }
                else {
                    request.data && request.data.success_message.includes('added') ? dispatch(changeTotalItem('inc')) : <></>
                    setSuccess(request.data.success_message)
                }
                setQuantity(1)
                setIsAdding(false)
            }
            catch (error) {
                setIsAdding(false)
                setError(catchError(error).error_message)
            }
        }

    }

    async function getProduct() {
        setPage(1)
        setIsLoading(true)
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/product/${encodeURIComponent(product)}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (request.data && request.data.error_message) {
                setIsLoading(false)
                setError(request.data.error_message)
            }
            else {
                setIsLoading(false)
                setData(request.data.data)
            }
        }
        catch (error) {
            setIsLoading(false)
            setError(catchError(error).error_message)
        }
    }


    function updateAvgRating(rating) {
        setAvgRating(Number((((avgRating * totalReview) + Number(rating)) / (totalReview + 1)).toFixed(1)))
    }

    useEffect(() => {
        dispatch(dropdown(false))
        setCustomQuantity('')
        product ? getProduct() : <></>
    }, [product])

    useEffect(() => {
        if (data !== null) {
            setTotalReview(data.total_reviews)
            setAvgRating(data.avg_rating)
        }
    }, [data])
console.log('publick path,--- ', process.env.PUBLIC_URL)
    return (
        <section>
            <Helmet>
                <title>
                    {product}
                </title>
            </Helmet>
            {
                isLoading === true || userInfoLoading ? <Loading /> :
                     error === errorName['404Error'] || error === errorName.internalError ? <NoConnection /> :
                        error === errorName.networkError ? <NoConnection network='true' /> :
                            error === errorName.authenticationError ? <NotAuthorized path={encodeURIComponent(path)} /> :
                                path && path?.includes('dukandar') && is_dukandar === false ? <NoData message={message.notDukandar} dukandar={true} /> :
                                    path && !path?.includes('dukandar') && is_dukandar === true ? <NoData message={message.notGrahak} button={true} /> :
                                        data !== null ?
                                            <div className='container'>
                                                <div className='d-flex' style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div className='h4 heading'>Product Detail</div>
                                                    <div className='detailproduct' data-aos='zoom-in'>
                                                        <img src={imgSrc} onLoad={() => setImgSrc(data.image)} style={imgSrc === fallbackImg ? { opacity: '0.6', width: '100%' } : { opacity: 1, width: '100%' }} alt={`${data.name}`} />
                                                        <h1 className=' mt-3 name' style={{ fontSize: '1.09rem', color: 'red', textAlign: 'center' }}>{data.name}</h1>
                                                        <h3 className='h6 mt-2'>MRP : <del>{data.mrp} Rs.</del></h3>
                                                        <h3 className='h6 mt-2'>Our Price : {data.price} Rs.</h3>
                                                        <h3 className='h6 mt-2'>Discount of {data.discount} %</h3>
                                                        {data.description !== '' ? <h4 className='h6 mt-2 text-center'>Description <br />{data.description}</h4> : <></>}
                                                        {is_dukandar === false ?
                                                            <div className='row'>
                                                                <div className='col-12 d-flex flex-row mt-3' style={{ gap: '20px', position: 'relative' }}>
                                                                    <input type='number' className='text-center h5' value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder='Enter KG' />
                                                                    {
                                                                        data.loose && <span onClick={() => setCustom(!custom)} className='position-absolute' style={{ right: '20px' }}> <CustomWeightIcon style={{ fill: 'goldenrod' }} /> </span>
                                                                    }
                                                                </div>
                                                                <div className='col-12 pt-1 pb-1 mt-3'>
                                                                    <button className='primary-button h6' onClick={() => add('add')} disabled={isAdding || data.is_active === false ? true : false}>{data.is_active === true ? 'Add To Cart' : 'Not Available'}</button> <br />
                                                                </div>
                                                            </div> : <></>}

                                                        {
                                                            data.offer ? <>
                                                                <div className='discounticon'> <DiscountIcon className=" fa-solid fa-layer-group fa-fade h6" /></div>
                                                                <div className='offer'>
                                                                    <div>{data.offer}</div>
                                                                </div>
                                                            </> : <></>
                                                        }

                                                        <div className={custom ? 'customWeight show' : 'customWeight'}>
                                                            <div className='hindi text-center' style={{ fontSize: "15px", color: 'blue', textDecoration: 'underline' }}>Custom Quantity</div>
                                                            <div className='hindi text-center'>प्रोडक्ट जिस भी मात्रा में उपलब्ध है, </div>
                                                            <div className='hindi'>
                                                                यदि उससे अधिक मात्रा में अपने हिसाब से लेना हो, जैसे <span>4kg 360gm </span> तो -
                                                                निचे quantity में <span>4</span> डाल कर <span>Add To Cart </span> करे, और <span> 360gm </span> यहाँ <span>अपडेट</span> करे  <br />
                                                                या उससे <span>कम मात्रा</span> में अपने हिसाब से लेना हो जैसे सिर्फ <span>50gm, 100gm, 350gm, 730gm </span> तो यहाँ अपडेट करे
                                                            </div>
                                                            <div className='d-flex'>
                                                                <input className='w-75' type='text' placeholder='50gm, 200gm, 340gm, 590gm, 750gm, 900gm' value={customQuantity} onChange={(e) => setCustomQuantity(e.target.value)} />
                                                                <button className='w-25 secondry-button pt-1 pb-1' style={{ width: 'fit-content' }} disabled={isAdding || data.is_active === false ? true : false} onClick={() => add('update')}>Update</button>
                                                                <span onClick={() => setCustom(!custom)}><CrossIcon className='crossIcon' /></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <h1 className='divider h5 mt-2 mb-2'>Product Reviews</h1>
                                                <h4 className='h6'>Average rating : {avgRating}</h4>
                                                <h4 className='h6 mt-1 mb-1'>Total reviews : {totalReview}</h4>
                                                <Reviews productId={data.id} setPerror={setError} page={page} setPage={setPage} setTotalReview={setTotalReview} updateAvgRating={updateAvgRating} reviewd={data.is_reviewd} />
                                                {
                                                    error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
                                                }
                                            </div> : <NoData message='Oops! Product not found in your dukan.' />
            }
        </section>
    )
}

export default DetailProduct