import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { ErrorUp, SuccessUp, catchError, url, Sloading, getToken, CrossIcon, EditIcon, DiscountIcon, CustomWeightIcon, fallbackImg } from '../base/index'
import { changeTotalItem } from '../redux/UserInfoSlice'
import { useDispatch } from 'react-redux'
import { setProductError } from '../redux/ProductSlice'

const SingleProduct = React.memo(({ item, setPerror, dukandar = false, tempImage = null, snsPage = null }) => {
    const [quantity, setQuantity] = useState(1)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [custom, setCustom] = useState(false)
    const [customQuantity, setCustomQuantity] = useState('')
    const [isAdding, setIsAdding] = useState(false)
    const [imgSrc, setImgSrc] = useState(fallbackImg)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    function change(e) {
        setQuantity(e.target.value)
    }

    async function add(button) {
        console.log('perro',setPerror, setPerror? 'hg' :'jh     ')
        if (button === 'add' && (quantity <= 0 || quantity === ''))
            setError('Enter qunatity!')
        else if (button === 'add' && quantity > 30)
            setError('Quantity can not be greater then 30')
        else if (button === 'update' && customQuantity.trim() === '') 
            setError('Enter your custom quantity !')
        else {
            setCustom(false)
            try {
                setIsAdding(true)
                const token = getToken()
                const request = await axios.post(`${url}/api/cartItem/`, { 'product': item.id, 'quantity': quantity, 'custom_quantity': customQuantity, 'button': button },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                if (request.data.error_message) {
                    setError(request.data.error_message)
                }
                else {
                    request.data && request.data.success_message.includes('added') ? dispatch(changeTotalItem('inc')) : <></>
                    setSuccess(request.data.success_message)
                }
                setQuantity(1)
                setIsAdding(false)
            }
            catch (error) {
                setIsAdding(false)
                setPerror ?
                    setPerror(catchError(error).error_message) :
                    dispatch(setProductError(catchError(error).error_message))
            }
        }

    }

    console.log('caling', item.id)
    return (<>
        <div className='col-sm-6 col-lg-4'>
            <div className='singleproduct'>
                {dukandar || snsPage ?
                    <img src={tempImage ? tempImage : item.image} style={{ width: '100%' }} alt={`${item.name}`} /> :
                    <img src={imgSrc} onLoad={() => setImgSrc(item.image)} style={imgSrc === fallbackImg ? { opacity: '0.6', width: '100%' } : { opacity: 1, width: '100%' }} alt={item.name} />
                }
                <div className='singleproductdetail'>
                    {dukandar === false ? <Link to={`/product/${item.slug}/`} className='h5'>{item.name}</Link> : <Link to={`/dukandar/product/${item.slug}/`} className='h5'>{item.name}</Link>}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 className='h6'>MRP : <del>{item.mrp} Rs. </del></h3>
                        <h3 className='h6'>Rating : {item.avg_rating}</h3>
                    </div> <hr className='m-0' />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 className='h6'>Our Price : {item.price} Rs.</h3>
                        <h3 className='h6'>{item.discount} % Off</h3>
                    </div>
                    {
                        !dukandar ?
                            <div className='row'>
                                <div className=' col-6 col-md-6 d-flex flex-row position-relative pe-0' style={{ gap: '20px' }}>
                                    <input type='number' className='text-center h6' value={quantity} onChange={(e) => change(e)} />
                                    {
                                        item.loose && <span onClick={() => setCustom(!custom)} className='position-absolute' style={{ right: '0px' }}> <CustomWeightIcon style={{ fill: 'goldenrod' }} /> </span>
                                    }
                                </div>
                                <div className='col-6 col-md-6 pt-1 pb-1 '>
                                    {
                                        isAdding === true ?
                                            <button className='primary-button h6 d-flex' disabled={true} style={{ justifyContent: 'center', alignItems: 'center', padding: '5px auto' }} ><Sloading color={'red'} width={'16px'} height={'fit-content'} /></button> :
                                            <button className='primary-button h6' style={{ padding: '5px 0px' }} disabled={item.is_active === false ? true : false} onClick={() => add('add')}>{item.is_active === true ? 'Add To Cart' : 'Not Available'}</button>
                                    }
                                </div>
                            </div> :
                            <span className='editicon' onClick={() => navigate(`/dukandar/product/?action=update&productToupdate=${encodeURIComponent(item.name)}`)}> <EditIcon style={{ 'fill': 'red', 'position': 'absolute', 'top': '9px', 'right': '13px', 'zIndex': 10 }} /> </span>

                    }
                    {
                        item.offer ? <>
                            <div className='discounticon'> <DiscountIcon className=" fa-fade h6" /></div>
                            <div className='offer'>
                                <div>{item.offer}</div>
                            </div>
                        </> : <></>
                    }

                    <div className={custom ? 'customWeight show' : 'customWeight'}>
                        <div className='hindi text-center' style={{ fontSize: "15px", color: 'blue', textDecoration: 'underline' }}>Custom Quantity</div>
                        <div className='hindi text-center'>प्रोडक्ट जिस भी मात्रा में उपलब्ध है, </div>
                        <div className='hindi'>
                            यदि उससे अधिक मात्रा में अपने हिसाब से लेना हो, जैसे <span>4kg 360gm </span> तो -
                            निचे quantity में <span>4</span> डाल कर <span>Add To Cart </span> करे, और <span> 360gm </span> यहाँ <span>अपडेट</span> करे  <br />
                            या उससे <span>कम मात्रा</span> में अपने हिसाब से लेना हो जैसे सिर्फ <span>50gm, 100gm, 350gm, 730gm </span> तो यहाँ अपडेट करे
                        </div>
                        <div className='d-flex'>
                            <input className='w-75' type='text' value={customQuantity} onChange={(e) => setCustomQuantity(e.target.value)} placeholder='50gm, 200gm, 340gm, 590gm, 750gm, 900gm' />
                            <button disabled={item.is_active === false ? true : false} onClick={() => add('update')} className='w-25 secondry-button pt-1 pb-1' style={{ width: 'fit-content' }}>Update</button>
                        </div>
                        <span onClick={() => setCustom(!custom)}><CrossIcon className='crossIcon' /></span>
                    </div>

                </div>
            </div>
        </div>
        {
            error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
        }
    </>
    )
})

export default SingleProduct;