// solve error and return response
function catchError(error) {
    // error.response.data.detail = given token not 
    // error.response.data.message = limit reached
    console.log(error) 
    return error && error.message === 'Network Error' ?
        {'error_message' : 'Network Error !', 'type' : 'exception'} :
        error.response.data.detail ?
            {'error_message' : error.response.data.detail, 'type' : 'exception'} :
            error.response.data.message ?
                {'error_message' : error.response.data.message, 'type' : 'normal'} :
                error.response.request.status === 500 ?
                    {'error_message' : 'Internal Error !', 'type' : 'exception'} :
                    {'error_message' : 'Internal Error !', 'type' : 'exception'}
}

// get the token or null
function getToken() {
    const token = localStorage.getItem('token')
    try {
        if (token) {
            return JSON.parse(token)
        }
        return null
    }
    catch (error) {
        return null
    }
}

// set the token 
function setToken(token) {
    localStorage.setItem('token', JSON.stringify(token))
}

// handle the createAsyncThunk request
function handleRequest(request, rejectWithValue) {
    return request.data && request.data.error_message ?
        rejectWithValue(request.data) :
        request.data
}




// get the date in this formate -> dd/mm/yy
function getDate(date = null) {
    if (date) {
        return new Date(date).toLocaleDateString('en-GB')
    }
    else {
        return new Date().toLocaleDateString('en-GB')
    }
}

// get the time in this formate -> 10:25:10 PM
function getTime(time = null) {
    const options = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    };

    if (time) {
        return new Date(time).toLocaleTimeString('en-US', options).toUpperCase();
    } else {
        return new Date().toLocaleTimeString('en-US', options).toUpperCase();
    }
}

// scroll to and also focus
function scrollFocus(ref) {
    const height = window.innerHeight;
    const width = window.innerWidth;

    if ((width > 650 && height > 950) || (width > 950 && height > 650)) {
        ref.current?.focus();
    }
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    ref.current.style.borderBottom = '2.17px solid red';
    setTimeout(() => {
        ref && ref.current && ref.current.style ? 
        ref.current.style.border = '' : <></> // Reset border color after 1 second
    }, 2000);
}

// enter key to move to anotherv input
function nextOnEnter(e, next) {
    if (e.key === 'Enter') {
        e.preventDefault(); // Prevent form submission
        if (next && next.current) {
            next.current.focus(); // Focus the next input field
        }
    }
}

// add, submit, call api, on shift + enter
function actionOnShiftEnter(e, action, data, dispatch = false) {
    e.key === 'Enter' && e.shiftKey ? dispatch ? dispatch(action(data)) : action(data) : <></>
}

// move down to the dropdown list



// change amonut format to 1,00,000
function changeNumberFormat(num) {
    return new Intl.NumberFormat('en-IN').format(num)
}

// open file , link, image in new tab 
function openLink(link) {
    const newLink = link.replace('http:', 'https:')
    window.open(newLink, '_blank');
}

// get the src from the iframe of map 
function getMapSrc(iframe) {
    const srcMatch = iframe.match(/src="([^"]+)"/);
    return srcMatch ? srcMatch[1] : null;
}


export default catchError;
export { getToken, handleRequest, getDate, getTime, setToken, getMapSrc, scrollFocus, nextOnEnter, actionOnShiftEnter, openLink, changeNumberFormat }

