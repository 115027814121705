import React, { useEffect } from 'react'
import { changeTotalItem, userInfoDefault } from '../../redux/UserInfoSlice'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
// import noconnection from '../../images/noconnection.png'

function NoConnection({ network}) {
  const dispatch = useDispatch()
  useEffect(() => {
    const backdrop = document.getElementsByClassName('modal-backdrop')
    backdrop.length > 0 ? backdrop[0].remove() : <></>
    if (network !== 'true' ) {
      dispatch(userInfoDefault())
      localStorage.removeItem('token')
      dispatch(changeTotalItem('set0'))
    }
  }, [dispatch]

  )

  return (
    <div className='container d-flex' style={{ justifyContent: 'center', alignItems: 'center', minHeight: '80vh', gap: '30px' }}>
      <Helmet>
        <title>No Connection</title>
      </Helmet>
      <img src={`${process.env.PUBLIC_URL}/images/noconnection.png`} alt='No Connection with server. Check your internet !' className='img-fluid' style={{ maxWidth: '250px' }} />
    </div>
  )
}

export default NoConnection