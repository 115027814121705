import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {catchError, url, handleRequest, getToken, setToken} from '../base/index'
import axios from 'axios'

// creating intial state
const intialState = {
    userData : null,
    userLoading : false,
    userError : null,
    userSuccess : null,
    userErrorType : null,
    userAction : null
}

const baseUrl = `${url}/api/user`


// creating api for the User
const createUser = createAsyncThunk('createUser', async ({username, email, password, default_dukan_id}, { rejectWithValue }) =>{
    try {
        const request = await axios.post(`${baseUrl}/createUser/`, {username, email, password, default_dukan_id}) 
        return handleRequest(request, rejectWithValue)
     }
     catch (error) {
        return rejectWithValue(catchError(error))
     }
});

const loginUser = createAsyncThunk('loginUser', async ({username, password, dukandar}, { rejectWithValue }) =>{
    try {
        const request = dukandar === false ? await axios.post(`${baseUrl}/loginUser/`,{username, password}) : await axios.post(`${url}/api/dukandar/loginDukanDar/`,{username, password}) 
        return handleRequest(request, rejectWithValue)

    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const forgotPassword = createAsyncThunk('forgotPassword', async ({username, email}, { rejectWithValue }) =>{
    try {
        const request = await axios.post(`${baseUrl}/forgotPassword/`,{username, email})
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const getUser = createAsyncThunk('getUser', async (_, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/profile/`, {
            headers: { 
                Authorization : `Bearer ${token}`,
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const updateUser = createAsyncThunk('upadteUser', async ({data}, { rejectWithValue }) =>{
    try {
        const token = getToken()
        const request = await axios.put(`${baseUrl}/profile/`, data , {
            headers : {
                Authorization :  `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const resetPassword = createAsyncThunk('resetPassword', async ({old_password, new_password}, { rejectWithValue }) =>{
    try {
        const token = getToken()
        const request = await axios.post(`${baseUrl}/resetPassword/`, {old_password, new_password} , {
            headers : {
                Authorization :  `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const userSlice = createSlice({
     name : "user",
     initialState : intialState,
     reducers : {
          userDefault(state, action) {
                state.userLoading = false
                state.userError = null
                state.userSuccess = null
                state.userErrorType = null
          },
          setUserError(state, action) {
            state.userError = action.payload
          },
          setUserErrorType(state, action) {
            state.userErrorType = action.payload
          }
     },
     extraReducers: (builder) => {
         builder
            .addCase(createUser.pending, (state) =>{
                state.userAction = 'createUser'
                state.userLoading = true
                state.userError = null
                state.userSuccess = null
                state.userErrorType = null
            })
            .addCase(createUser.fulfilled, (state, action) =>{
                state.userLoading = false
                state.userSuccess = action.payload.success_message
                setToken(action.payload.access_token)
            })
            .addCase(createUser.rejected, (state, action) =>{
                state.userLoading = false
                state.userError = action.payload.error_message
                state.userErrorType = action.payload.type
            }) // createUser


            .addCase(loginUser.pending, (state) =>{
                state.userAction = 'loginUser'
                state.userLoading = true
                state.userError = null
                state.userSuccess = null
                state.userErrorType = null
            })
            .addCase(loginUser.fulfilled, (state, action) =>{
                state.userLoading = false
                state.userSuccess = action.payload.success_message
                setToken(action.payload.access_token)
            })
            .addCase(loginUser.rejected, (state, action) =>{
                state.userLoading = false
                state.userError = action.payload.error_message
                state.userErrorType = action.payload.type
            })  // loginUser

            
            .addCase(forgotPassword.pending, (state) =>{
                state.userAction = 'forgotPassword'
                state.userLoading = true
                state.userError = null
                state.userSuccess = null
                state.userErrorType = null
            })
            .addCase(forgotPassword.fulfilled, (state, action) =>{
                state.userLoading = false
                state.userSuccess = action.payload.success_message
            })
            .addCase(forgotPassword.rejected, (state, action) =>{
                state.userError = action.payload.error_message
                state.userErrorType = action.payload.type
                state.userLoading = false
            }) // forgotPassword


            .addCase(getUser.pending, (state) =>{
                state.userAction = 'getUser'
                state.userLoading = true
                state.userError = null
                state.userSuccess = null
                state.userErrorType = null
            })
            .addCase(getUser.fulfilled, (state, action) =>{
                state.userLoading = false
                state.userData = action.payload.data
            })
            .addCase(getUser.rejected, (state, action) =>{
                state.userLoading = false
                state.userError = action.payload.error_message
                state.userErrorType = action.payload.type
            })  // getUser


            .addCase(updateUser.pending, (state) =>{
                state.userAction = 'updateUser'
                state.userLoading = true
                state.userError = null
                state.userSuccess = null
                state.userErrorType = null
            })
            .addCase(updateUser.fulfilled, (state, action) =>{
                state.userLoading = false
                state.userData = action.payload.data
                state.userSuccess = action.payload.success_message
            })
            .addCase(updateUser.rejected, (state, action) =>{
                state.userLoading = false
                state.userError = action.payload.error_message
                state.userErrorType = action.payload.type
            })  // updateUser

            
            .addCase(resetPassword.pending, (state) =>{
                state.userError = null
                state.userSuccess = null
                state.userLoading = true
                state.userErrorType = null
                state.userAction = 'resetPassword'
            })
            .addCase(resetPassword.fulfilled, (state, action) =>{
                state.userSuccess = action.payload.success_message
                state.userLoading = false
            })
            .addCase(resetPassword.rejected, (state, action) =>{
                console.log(action.payload)
                state.userError = action.payload.error_message
                state.userErrorType = action.payload.type
                state.userLoading = false
            }) // resetPassword
     }      
})

export default userSlice;
export { getUser, updateUser, createUser, loginUser, forgotPassword, resetPassword };
export const { userDefault, setUserError, setUserErrorType } = userSlice.actions