import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {catchError, errorName, getToken, handleRequest, url} from '../base'
import axios from 'axios'

// creating intial state

const intialState = {
    productSuccess : null,
    productError : null,
    productLoading : true,
    productErrorType : null,

    categoryProductData : null,
    categoryFirstTime : true,
    categoryLastPage : false,
    categoryPage : 1,
    categoryData : null,
}

const baseUrl = `${url}/api`


// creating api for the User
const getCategory = createAsyncThunk('getCategory', async (page, { rejectWithValue }) =>{
      try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/category/`, {
            headers : {
                Authorization : `Bearer ${token}`
            },
            params : {
                page : page
            }
        })
        return handleRequest(request, rejectWithValue)
     }
     catch (error) {
        return rejectWithValue(catchError(error))
     }
});

const getCategoryProducts = createAsyncThunk('getCategoryProducts', async ({page, category}, { rejectWithValue }) =>{
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/category/${category}/products/`, {
            headers : {
                Authorization : `Bearer ${token}`
            },
            params : {
                page : page
            }
        })
        return handleRequest(request, rejectWithValue)
     }
     catch (error) {
        return rejectWithValue(catchError(error))
     }
});



const productSlice = createSlice({
     name : 'product',
     initialState : intialState,
     reducers : {
        productDefault(state, action){
            state.productError = null
            state.productSuccess = null
            state.productErrorType = null
        },
        setProductError(state, action) {
            state.productError = action.payload.error_message
            state.productErrorType = action.payload.type
        },
        incCategoryPage(state, action) {
            state.categoryPage = state.categoryPage + 1
        },
        nullCategory(state, action) {
            state.categoryFirstTime = true
            state.categoryData = null
            state.categoryLastPage = false
            state.categoryPage =1
        },
        defaultCategoryProductsData(state, action) {
            console.log('caling default ')
            state.categoryProductData = null
            state.productLoading = true
            state.productError = null
        }
     },
     extraReducers: (builder) => {
         builder
            .addCase(getCategory.pending, (state) =>{
                state.productLoading = true
                state.productError = null
                state.productSuccess = null
                state.productErrorType = null
            })
            .addCase(getCategory.fulfilled, (state, action) =>{
                state.productLoading = false
                state.categoryData = state.categoryData === null ? action.payload.data : [...state.categoryData , ...action.payload.data]
                state.categoryFirstTime = false
            })
            .addCase(getCategory.rejected, (state, action) =>{
                 state.productLoading = false
                 action.payload.error_message === errorName.invalidPage ? state.categoryLastPage = true : 
                 state.productError = action.payload.error_message
                 state.productErrorType = action.payload.type
            }) //getCategory

            
            .addCase(getCategoryProducts.pending, (state) =>{
                state.productLoading = true
                state.productError = null
                state.productSuccess = false
                state.productErrorType = null
            })
            .addCase(getCategoryProducts.fulfilled, (state, action) =>{
                state.productLoading = false
                console.log('productdat', action.payload.data)
                state.categoryProductData = state.categoryProductData === null ? action.payload.data : [...state.categoryProductData , ...action.payload.data]
            })
            .addCase(getCategoryProducts.rejected, (state, action) =>{
                console.log(action.payload)
                state.productLoading = false
                state.productError = action.payload.error_message
                state.productErrorType = action.payload.type
            }) // getCategoryProduct
     }      
})

export default productSlice;
export { getCategory, getCategoryProducts };
export const { productDefault, incCategoryPage, nullCategory, defaultCategoryProductsData, setProductError } = productSlice.actions
