import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EditIcon, fallbackImg } from '../base'



const SingleCategory = React.memo(({ item, dukandar = false}) => {
  const navigate = useNavigate()
  const [imgSrc, setImgSrc] = useState(fallbackImg)

  return (
    item && <div className='col-sm-6 col-lg-4' style={{ overflow: 'hidden' }}>
      <div className='singlecategory' data-aos="zoom-in-up">
        
        {
          dukandar ? <img src={item.image} alt={item.name} /> : 
          <img src={imgSrc} onLoad={()=>setImgSrc(item.image)} alt={item.name} style={imgSrc === fallbackImg ? { opacity: '0.6', width: '100%' } : { opacity: 1, width: '100%' }}/>
        }
        {/* <img src = {`${url}/${item.image.split(':8000/')[1]}`} alt={`${item.name}`}/> */}
        <div className='singlecategorydetail'>
          <h1 className='h5' style={{color : 'red'}}> {item.name}</h1>
          <h1 className='h5'>Total Products : {item.total_products}</h1>
          {dukandar ?
            <button className='secondry-button h6 mb-3 mt-3' onClick={() => navigate(`/dukandar/product/?category=${encodeURIComponent(item.slug)}`)}>View products</button> :
            <button className='secondry-button h6 mb-3 mt-3' onClick={() => navigate(`/category/${item.slug}/products/`)}>View products</button>
          }

          {dukandar ?
            <span onClick={() => navigate(`/dukandar/category/?action=update&categoryToupdate=${encodeURIComponent(item.slug)}`)}>
              <EditIcon style={{ 'position': 'absolute', 'fill': 'red', 'top': '5px', 'right': '14px' }} />
            </span> : <></>
          }
        </div>
      </div>
    </div>
  )
})

export default SingleCategory