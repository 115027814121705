import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dropdown, changeDefaultDukan, changeTotalItem, notificationChange } from "../../redux/UserInfoSlice";
import { url, catchError, Sloading, SuccessUp, getToken, errorName, message, ErrorUp, getMapSrc, openLink, SearchIcon, CrossIcon, GroupWhatsappIcon } from "../../base/index";
import { Loading, NoConnection, NoData, NotAuthorized } from "../../base/index";
import axios from "axios";
import { Helmet } from "react-helmet";
import { nullCategory } from "../../redux/ProductSlice";
import { nullOrder } from "../../redux/OrderSlice";
import { useLocation } from "react-router-dom";

function ApniDukan() {
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(true)
    const [dukanProfile, setDukanProfile] = useState(null)
    const path = window.location.pathname
    const baseUrl = `${url}/api/user`
    const dispatch = useDispatch()
    const { userInfoLoading, default_dukan, user, is_dukandar, dukan_name } = useSelector((state) => state.userInfo)
    const location = useLocation()
    const dukan_id = new URLSearchParams(location.search).get('dukan_id')
    const [dukans, setDukans] = useState(null)
    const [pin, setPin] = useState('')
    const [dukan, setDukan] = useState({ 'name': '', 'id': '' })

    async function getApniDukan(dukan_id) {
        setLoading(true)
        setError(null)
        setDukanProfile(null)
        setDukans(null)
        try {
            const token = getToken()
            const request = await axios.get(`${baseUrl}/getApniDukan/?dukan_id=${dukan_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setLoading(false)
            if (request.data && request.data.error_message) {
                setError(request.data.error_message)
            }
            else {
                setDukanProfile(request.data.data)
                setDukan({ 'name': request.data.data.name, 'id': dukan_id })
            }
        }
        catch (error) {
            setLoading(false)
            setError(catchError(error).error_message)
        }
    }


    async function setApniDukan(dukan_id) {
        setLoading(true)
        setError(null)
        setSuccess(null)
        try {
            const token = getToken()
            const request = await axios.put(`${baseUrl}/setApniDukan/?dukan_id=${dukan_id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setLoading(false)
            if (request.data && request.data.error_message) {
                setError(request.data.error_message)
            }
            else {
                dispatch(changeDefaultDukan({ 'id': dukan_id, 'name': dukanProfile.name }))
                dispatch(changeTotalItem({ 'type': 'specific', 'total_item': request.data.total_item }))
                dispatch(notificationChange({ 'data': request.data.notification }))
                dispatch(nullCategory())
                dispatch(nullOrder())
                setSuccess(request.data.success_message)
            }
        }
        catch (error) {
            setLoading(false)
            setError(catchError(error).error_message)
        }
    }



    function selectDukan(item) {
        setDukan({ 'name': item.name, 'id': item.id })
        getApniDukan(item.id)
    }


    async function searchDukan() {
        if (pin.length !== 6) {
            setError('Please Enter 6 digit Pin !')
        }
        else {
            setError(null)
            setDukans(null)
            try {
                const request = await axios.get(`${url}/api/user/searchApniDukan/?pin=${pin.trim()}`)
                request.data.error_message ? setError('Oops! No dukan found with this pin.') : setDukans(request.data)
            }
            catch (error) {
                setError('Oop! No dukan found with this pin.')
            }
        }
    }


    useEffect(() => {
        dispatch(dropdown(false))
    }, [dispatch])

    useEffect(() => {
        dukan_id ? getApniDukan(dukan_id) : default_dukan ? getApniDukan(default_dukan) : setLoading(false)
    }, [default_dukan, dukan_id])

    return (
        <section className="apnidukan" style={{ position: 'relative', paddingBottom: '20px', overflow: 'hidden' }}>
            <Helmet>
                <title>Apni Dukan</title>
            </Helmet>
            {
                loading || userInfoLoading ? <Loading /> :
                    error === errorName["404Error"] || error === errorName.internalError ? <NoConnection /> :
                        error === errorName.networkError ? <NoConnection network='true' /> :
                            user === null || error === errorName.authenticationError ? <NotAuthorized path={`${path}${location.search}`} /> :
                                is_dukandar === true || error === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                                    <>
                                        <div className="h5" style={{ textAlign: 'center', marginTop: '10px', overflow: 'hidden' }}>
                                            Apki Apni Dukan :<div className="heading h4 mt-0"> {dukan_name}</div>
                                        </div>
                                        <div style={{ maxWidth: '280px', margin: 'auto', justifyContent: 'center', overflow: 'visible' }}>
                                            <div className='searchlist'>
                                                <div className='searchlistinput' style={{ width: '100%', overflow: 'visible' }}>
                                                    <input className='h6 pb-1' style={{ flex: 1 }} value={pin} onChange={(e) => setPin(e.target.value)} type='number' placeholder='Search dukan with Pin code' />
                                                    {
                                                        dukans === null ?
                                                            <span onClick={() => searchDukan()}>
                                                                <SearchIcon style={{ position: 'absolute', right: '0px', top: '-4px', cursor: 'pointer', fill: 'red', backgroundColor: 'white' }} />
                                                            </span>
                                                            :
                                                            <span onClick={() => setDukans(null)}>
                                                                <CrossIcon style={{ position: 'absolute', right: '0px', top: '-5px', fill: 'gray', overflow: 'hidden', cursor: 'pointer', width: '18px' }} />
                                                            </span>
                                                    }
                                                </div>
                                                <div className="searchlistdiv mt-3" style={{ display: `${dukans === null ? 'none' : 'flex'}`, top: '18px', width: '100%' }}>
                                                    <div style={{ width: '100%' }}>{
                                                        dukans && dukans.map((item, key) => {
                                                            return (
                                                                <div className="searchlistvalue" key={key} >
                                                                    <h1 className="h6" key={key} onClick={() => selectDukan(item)}>{item.name} </h1>
                                                                    <hr style={{ margin: '0px' }} />
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            dukanProfile ? <div className=' apniDukan' style={{ maxWidth: '1100px', width: '95%', margin: 'auto', }}>
                                                <div className="h5" style={{ textAlign: 'center', marginTop: '20px', overflow: 'hidden' }}>
                                                    <div className="heading h4 mt-2">{dukanProfile.name}, <span style={{ color: 'black', textDecoration: 'none' }}>Details</span></div>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                                    <div className='dukandetail mt-3 d-flex' style={{ flexDirection: 'column', alignItems: 'center' }}>
                                                        <img src={dukanProfile.banner_image} alt='banner' className='img-fluid bannerimage' />
                                                        <div className='ownerimage m-auto mt-4'>
                                                            <img src={dukanProfile.owner_image} className='img-fluid' style={{ height: '100%', width: '100%' }} alt='Malik' />
                                                        </div>

                                                        <h5 className='h4 mt2 mb-2 heading'>{dukanProfile.owner}</h5>
                                                        <p className='h6 m-auto' style={{ width: 'fit-content', textAlign: 'center' }}>{dukanProfile.about}</p>

                                                        <div className='dukandetail row g-3 mt-5'>
                                                            <div className='col-sm-5 col-md-6 '>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Dukan Id : </span>MKD-{dukanProfile.id}</h1>
                                                            </div>
                                                            <div className='col-sm-7 col-md-6'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Owner : </span>{dukanProfile.owner}</h1>
                                                            </div>
                                                            <div className='col-sm-5 col-md-6'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Dukan : </span>{dukanProfile.name}</h1>
                                                            </div>
                                                            <div className='col-sm-7 col-md-6'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Address : </span>{dukanProfile.address}, {dukanProfile.city}</h1>
                                                            </div>
                                                            <div className='col-sm-5 col-md-6'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Phone no. : </span>{dukanProfile.phone_number}</h1>
                                                            </div>
                                                            <div className='col-sm-7 col-md-6'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Whatsapp no. : </span>{dukanProfile.whatsapp_number}</h1>
                                                            </div>
                                                            <div className='col-6 col-sm-5 col-md-6 col-lg-3'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Total Products : </span>{dukanProfile.total_product}</h1>
                                                            </div>
                                                            <div className='col-6 col-sm-7 col-md-6 col-lg-3'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Total Category : </span>{dukanProfile.total_category}</h1>
                                                            </div>
                                                            <div className='col-6 col-sm-5 col-md-6 col-lg-3'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Total Orders : </span>{dukanProfile.total_order}</h1>
                                                            </div>
                                                            <div className='col-6 col-sm-7 col-md-6 col-lg-3'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Total Users : </span>{dukanProfile.total_user}</h1>
                                                            </div>
                                                            <div className='12'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Delivery Available in : </span>{dukanProfile.available_in}</h1>
                                                            </div>
                                                            <div className='col-12'>
                                                                <h1 className='h6'><span style={{ color: 'red' }}>Other Details : </span></h1>
                                                                {
                                                                    dukanProfile.other_details.split('/n').map((item, key) => {
                                                                        return <p key={key} className='h6'>{key + 1}. {item}</p>
                                                                    })
                                                                }
                                                            </div>

                                                            {
                                                                getMapSrc(dukanProfile.map_location) ?
                                                                    <div className='col-12 dukanmap' style={{ textAlign: 'center' }}>
                                                                        <h1 className='h5 mt-2 mb-3'><span style={{ color: 'red' }}>Location </span></h1>
                                                                        <iframe title="mahajan kirana map" src={getMapSrc(dukanProfile.map_location)} style={{ border: "0", height: 'inherit', width: '100%' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                                    </div> : <></>
                                                            }

                                                        </div>

                                                        <button className='secondry-button h6 ps-5 pe-5 pt-1 pb-1 mt-3' style={{ width: 'fit-content' }} onClick={() => setApniDukan(dukan.id, true)}>Choose Dukan</button>
                                                    </div>

                                                    {/* whatsapp group icon */}
                                                    {
                                                        dukanProfile.whatsapp_link === '' || dukanProfile.whatsapp_link === null ? <></> :
                                                            <div style={{ right: '13px', position: 'absolute', bottom: '-6px', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1px', cursor: 'pointer' }} onClick={() => openLink(dukanProfile.whatsapp_link)}>
                                                                <span>
                                                                    <GroupWhatsappIcon style={{ lineHeight: 'normal', width: '30px', fill: 'green' }} />
                                                                </span>
                                                                <h6 style={{ fontSize: '12px', color: 'green' }}>Join Group</h6>
                                                            </div>
                                                    }

                                                </div>
                                            </div> : <NoData message='Oops! No dukan found.' />
                                        }
                                        {
                                            error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
                                        }
                                    </>

            }
        </section>
    )
}

export default ApniDukan;



// async function apni_dukan(apniDukan, set = false) {
//     setLoading(true)
//     setError(null)
//     setDukanProfile(null)
//     setSuccess(null)
//     setDukans(null)
//     try {
//         const token = JSON.parse(localStorage.getItem('token'));
//         const request = await axios.get(`${baseUrl}/api/user/apniDukan/?apniDukan=${apniDukan}&set=${set}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`
//             }
//         })

//         setLoading(false)
//         if (request.data.error_message)
//             setError(request.data.error_message)
//         else if (request.data.data) {
//             setDukanProfile(request.data.data)
//             setDukan({ 'name': request.data.data.name, 'id': apniDukan })
//             if (request.data.success_message) {
//                 dispatch(changeDefaultDukan(apniDukan))
//                 dispatch(changeTotalItem({ 'type': 'specific', 'total_item': request.data.total_item }))
//                 dispatch(notificationChange({ 'data': request.data.notification }))
//                 dispatch(nullCategory())
//                 setSuccess(request.data.success_message)
//             }
//         }
//     }
//     catch (error) {
//         setLoading(false)
//         setError(catchError(error))
//     }
// }

{/* <div className={show === true ? 'dukandropdown show' : 'dukandropdown'}>
                                        <div style={{width : '100%'}}>
                                        {
                                        searchLoading ? <div style={{display : 'flex', justifyContent : 'center'}}><Sloading width={25} height={'fit-content'} color='blue'/></div>:
                                            showDukan && showDukan.map((item, key) => {
                                                return (
                                                    <div key={key}>
                                                        <h1 className='h6' onClick={() => apni_dukan(item)}>{item}</h1>
                                                        <hr className="mt-1 mb-1" />
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>  
                                    </div> */}