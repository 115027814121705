import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dropdown } from '../../redux/UserInfoSlice'
import axios from 'axios'
import { url, Sloading, ErrorUp, NoData, NoConnection, NotAuthorized, Loading, catchError, getDate, getTime, getToken, errorName, message, SuccessUp, nextOnEnter, scrollFocus, CrossIcon, PhoneIcon, WhatsappIcon, PlusIcon } from '../../base/index'
import BillDeskItem from '../../components/BillDeskItem'
import { getSearchedProduct, getDukanProfile, dukandarDefault, saveOrderPdf, setSearchedProduct, updateDukanOrder, getSearchedCustomer, setSearchedCustomer, setDukandarError } from '../../redux/DukandarSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Helmet } from 'react-helmet'

function BillDesk() {
  const dukandarSuccess = useSelector((state) => state.dukandar.dukandarSuccess)
  const [pdfSaving, setPdfSaving] = useState(false)
  const dispatch = useDispatch()
  const [uniqueId, setUniqueId] = useState(1)
  const { is_dukandar, userInfoLoading, user } = useSelector((state) => state.userInfo)
  const { dukanProfile, dukandarError, dukandarErrorType, searchedProduct, searchedCustomer } = useSelector((state) => state.dukandar)
  const [list, setList] = useState(null)
  const [display, setDisplay] = useState('none')
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [saving, setSaving] = useState(0)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [products, setProducts] = useState(null)
  const [quantities, setQuantities] = useState({});
  const [d1, setD1] = useState({ 'name': 'Special Discount', 'number': 0, 'percentage': 0, 'show': true })
  const [d2, setD2] = useState({ 'name': 'Additional Discount', 'number': 0, 'percentage': 0, 'show': true })
  const [cdetail, setCdetail] = useState({ 'name': '', 'house_no': '', 'street_no': '', 'colony': '', 'city': '', 'number': '' })
  const [otherProducts, setOtherProducts] = useState({ 'name': '', 'total': 0, 'show': true })
  const [aditionalP, setAditionalP] = useState({ 'name': '', 'offer': '', 'mrp': '', 'price': '', 'display': 'none', 'list': null, 'loading': false })
  const [using, setUsing] = useState('number')
  const [query, setQuery] = useState('')
  const [customer, setCustomer] = useState(null)
  const [customerLoading, setCustomerLoading] = useState(false)
  const [customerDisplay, setCustomerDisplay] = useState('none')
  const [customerLink, setCustomerLink] = useState(false)
  const path = window.location.pathname
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const bill_id = queryParams.get('bill_id')
  const reffrence = {
    searchInputRef: useRef(null),
    customerRef: useRef(null),
    nameRef: useRef(null),
    mrpRef: useRef(null),
    priceRef: useRef(null),
    offerRef: useRef(null),
    d1NameRef: useRef(null),
    d1NumberRef: useRef(null),
    d1PercentageRef: useRef(null),
    d2NameRef: useRef(null),
    d2NumberRef: useRef(null),
    d2PercentageRef: useRef(null),
    cnameRef: useRef(null),
    chouseRef: useRef(null),
    cstreetRef: useRef(null),
    ccolonyRef: useRef(null),
    ccityRef: useRef(null),
    cnumberRef: useRef(null),
    otherPnameRef: useRef(null),
    otherPtotalRef: useRef(null),
  }
  const [adtionalFocusIndex, setAditionalFocusIndex] = useState(-1)
  const [inputFocusIndex, setInputFocusIndes] = useState(-1)
  const [customerFocusIndex, setCustomerFocusIndex] = useState(-1)
  const navigate = useNavigate()

  function shiftEnter(e) {
    if (e.key === 'Enter' && e.shiftKey) {
      addAdditionalProduct()
      scrollFocus(reffrence.nameRef)
    }
  }


  function handleKeyDown(e, ref, index, setIndex, data) {
    if (data && e.key === 'ArrowDown') {
      index < data.length - 1 ? setIndex(prev => prev + 1) : setIndex(0)
      e.preventDefault(); // Prevent default scrolling
    }
    else if (data && e.key === 'ArrowUp') {
      index > 0 ? setIndex(prev => prev - 1) : setIndex(data.length - 1)
      e.preventDefault();
    }
    else if (data && e.key === 'Enter' && index >= 0 && index < data?.length) {
      setIndex(-1)
      ref.current === reffrence.mrpRef.current ? selectAditionalP(aditionalP?.list[index]) : ref.current === reffrence.searchInputRef.current ? select(customer[index]) : getProduct(list[index])
    }
    else if (data && (e.key === 'ArrowRight' || e.key === 'ArrowLeft')) {
      setIndex(-1)
    }
    else if (e.key === 'Enter') {
      ref.current === reffrence.mrpRef.current ? setAditionalP((prev) => { return { ...prev, 'display': 'none' } }) : ref.current === reffrence.searchInputRef.current ? setCustomerDisplay('none') : setDisplay('none')
      index && setIndex ? setIndex(-1) : <></>
      nextOnEnter(e, ref)
    }
    else if (e.key === 'Delete') {
      setIndex && setIndex(-1)
      ref.current === reffrence.nameRef.current ? setSearch('') : ref.current === reffrence.mrpRef.current ? setAditionalP((prev) => { return { ...prev, 'name': '' } }) : ref.current === reffrence.searchInputRef.current ? setQuery('') : <></>
    }
    console.log(e.key, ref.current, reffrence.searchInputRef.current)
  }


  // for customer details
  function select(item) {
    setCustomerDisplay('none')
    dispatch(getSearchedCustomer(item.id))
  }

  async function searchCustomer() {
    setCustomerLoading(true)
    setCustomerDisplay('flex')
    try {
      const token = getToken()
      const request = await axios.get(`${url}/api/dukandar/searchCustomer/?using=${using}&query=${query}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (request.data && request.data.data) {
        setCustomerLoading(false)
        setCustomer(request.data.data)
        setCustomerDisplay('flex')
      }
      else if (request.data && request.data.error_message) {
        setCustomerDisplay('none')
        setCustomerLoading(false)
      }
    }
    catch (error) {
      setCustomerLoading(false)
      setCustomerDisplay('none')
    }
  }

  function changeUsing(using) {
    setQuery('')
    setUsing(using)
  }




  // search the product with words from search and additional input field
  async function searchProduct(word, type) {
    if (type === 'search') {
      setList(null)
      setLoading(true)
      try {
        const token = getToken()
        const request = await axios.get(`${url}/api/dukandar/product/search/?category=All-Category&product=${encodeURIComponent(word)}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        setLoading(false)
        return request.data && request.data.data ? setList(request.data.data) : setDisplay('none')
      }
      catch (error) {
        setLoading(false)
        setDisplay('none')
      }
    }
    else {
      setAditionalP((prev) => { return { ...prev, 'loading': true, 'data': null } })
      try {
        const token = getToken()
        const request = await axios.get(`${url}/api/dukandar/product/search/?category=All-Category&product=${encodeURIComponent(word)}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        return request.data && request.data.data ? setAditionalP((prev) => { return { ...prev, 'loading': false, 'list': request.data.data } }) : setAditionalP((prev) => { return { ...prev, 'loading': false, 'display': 'none' } })
      }
      catch (error) {
        setAditionalP((prev) => { return { ...prev, 'loading': false, 'display': 'none' } })
      }
    }
  }

  // change the search input list field
  function changeInput(e) {
    setAditionalP((prev) => {
      return { ...prev, 'display': 'none' }
    })
    setSearch(e.target.value)
    if (e.target.value.length > 2) {
      setDisplay('flex')
      searchProduct(e.target.value, 'search')
    }
    else {
      setList(null)
      setDisplay('none')
    }
  }

  // get the product from the search list
  function getProduct(item) {
    scrollFocus(reffrence.searchInputRef)
    setError(null)
    dispatch(getSearchedProduct({ 'item': encodeURIComponent(item), 'billDesk': 'True' }))
  }

  // clear the searh input field
  function clearInput() {
    setSearch('')
    setList(null)
    setDisplay('none')
  }


  // additional / extra product input field change
  function changeAditionalP(e) {
    // setDisplay('none')
    if (e.target.name === 'name' && e.target.value.length > 2) {
      searchProduct(e.target.value)
    }
    setAditionalP((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        'display': e.target.name === 'name' && e.target.value.length > 2 ? 'flex' : 'none',
        'list': e.target.name === 'name' && e.target.value.length > 2 ? list : null
      }
    })
  }

  // ṛuns when aditional extra product is selected from the aditionalP list 
  function selectAditionalP(item) {
    scrollFocus(reffrence.nameRef)
    setAditionalP((prev) => { return { ...prev, 'name': item.split(' ').slice(0, -1).join(' '), 'display': 'none' } })
  }

  // add additional / extra product in the bill desk
  function addAdditionalProduct() {
    setProducts((prev) => {
      return prev === null ? [{ ...aditionalP, 'mrp': mrp <= 0 || mrp === '' ? 0 : mrp, 'price': price === 0 || price === '' ? mrp : price, 'uniqueId': uniqueId }] : [{ ...aditionalP, 'mrp': mrp <= 0 ? 0 : mrp, 'price': price <= 0 || price === '' ? mrp : price, 'uniqueId': uniqueId }, ...prev]
    })
    setQuantities((prev) => ({ ...prev, [`uniqueId_${uniqueId}`]: 1 }));
    const mrp = Number(aditionalP.mrp)
    const price = Number(aditionalP.price)
    setTotalQuantity((prev) => { return prev + 1 })
    setSaving((prev) => { return prev + (mrp - price) })
    setTotal((prev) => { return prev + price })
    setAditionalP({ 'name': '', 'offer': '', 'mrp': '', 'price': '', 'display': 'none' })
    setUniqueId(prev => prev + 1)
  }


  // change other products or additional items 
  function changeOtherProducts(e) {
    setOtherProducts((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.name === 'total' ? e.target.value > 0 || e.target.value === '' ? e.target.value : 0 : e.target.name === 'show' ? !prev.show : e.target.value
      }
    })

  }



  // remove the item from bill desk
  function removeProduct(id) {
    setProducts(products.filter((item, index) => {
      return index !== id
    }))
  }

  // change discount no. 1
  function changeD1(makeNull = null, e) {
    const { name, value } = e.target
    setD1((prev) => {
      return {
        ...prev,
        [makeNull]: 0,
        [name]: name === 'name' ? value : name === 'show' ? !prev.show : value === '' || value <= 0 || value === '0' ? 0 : value
      }
    })
  }

  // change discount no. 2
  function changeD2(makeNull = null, e) {
    const { name, value } = e.target
    setD2((prev) => {
      return {
        ...prev,
        [makeNull]: 0,
        [name]: name === 'name' ? value : name === 'show' ? !prev.show : value === '' || value <= 0 || value === '0' ? 0 : value
      }
    })
  }


  // change quantity of the item
  function handleQuantityChange(name, newQuantity) {
    setQuantities((prev) => ({ ...prev, [name]: newQuantity }));
  };

  // calls when dukandar create bill with order 
  async function getItems() {
    try {
      const token = getToken()
      const request = await axios.get(`${url}/api/dukandar/order/${bill_id}/?billDesk=True`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (request.data && request.data.error_message && request.data.error_message.includes('Invalid order Id')) {
        setError('Oops! Invalid order Id .')
      }
      else if (request.data && request.data.type === 'exception') {
        dispatch(setDukandarError(request.data.error_message))
      }
      else if (request.data && request.data.error_message) {
        setError(request.data.error_message)
        setD1((prev) => { return { ...prev, 'number': request.data.discount } })
        setOtherProducts({ 'name': request.data.additional, 'total': request.data.additional_total, 'show': true })
        setCdetail({ 'name': request.data.cname, 'colony': request.data.colony, 'city': request.data.city, 'number': request.data.number, 'house_no': request.data.house_no, 'street_no': request.data.street_no })
      }
      else {
        const newData = request.data.data.map((item, index) => { return { ...item, ['uniqueId']: `${index + 1}` } })
        setProducts(newData)
        request.data.data.map((item, index) => {
          return [
            setQuantities((prev) => ({ ...prev, [`uniqueId_${index + 1}`]: item.quantity })),
            setTotalQuantity((prev) => { return prev + item.quantity }),
            setSaving((prev) => { return prev + item.mrp - item.price }),
            setTotal((prev) => { return prev + (item.price * item.quantity) }),
            setUniqueId(prev => prev + 1)
          ]
        })

        setD1((prev) => { return { ...prev, 'number': request.data.discount } })
        setOtherProducts({ 'name': request.data.additional, 'total': request.data.additional_total, 'show': true })
        setCdetail({ 'name': request.data.cname, 'colony': request.data.colony, 'city': request.data.city, 'number': request.data.number, 'house_no': request.data.house_no, 'street_no': request.data.street_no })
      }
    }
    catch (error) {
      console.log(error)
      dispatch(setDukandarError(catchError(error).error_message))
    }
  }


  // set all to default
  function setDefault() {
    setProducts(null)
    setOtherProducts({ 'name': '', 'total': 0, 'show': true })
    setD1({ 'name': 'Special Discount', 'number': 0, 'percentage': 0, 'show': true, })
    setD2({ 'name': 'Additional Discount', 'number': 0, 'percentage': 0, 'show': true })
    setAditionalP({ 'name': '', 'offer': '', 'mrp': '', 'price': '', 'display': 'none', 'list': null, 'loading': false })
    setTotal(0)
    setCdetail({ 'name': '', 'colony': '', 'city': '', 'number': '', 'house_no': '', 'street_no': '' })
    setTotalQuantity(0)
    setSaving(0)
    setQuantities({})
    navigate('/dukandar/billDesk/')
  }

  // save the pdf and send to backend
  async function save() {
    setSuccess(null)
    if (cdetail.name.trim() === '') {
      scrollFocus(reffrence.cnameRef)
      setError('Enter customer name !')
    }
    else if (cdetail.house_no < 0 || cdetail.house_no === '') {
      setError('Enter house number !')
      scrollFocus(reffrence.chouseRef)
    }
    else if (cdetail.street_no < 0 || cdetail.street_no === '') {
      setError('Enter street number !')
      scrollFocus(reffrence.cstreetRef)
    }
    else if (cdetail.colony.trim() === '') {
      scrollFocus(reffrence.ccolonyRef)
      setError('Enter customer colony !')
    }
    else if (cdetail.city.trim() === '') {
      scrollFocus(reffrence.ccityRef)
      setError('Enter city !')
    }
    else if (cdetail.number.toString().length !== 10 || cdetail.number <= 0) {
      scrollFocus(reffrence.cnumberRef)
      setError('Enter 10 digit whatsapp number !')
    }
    else if (!Number(cdetail.number)) {
      scrollFocus(reffrence.cnumberRef)
      setError('Enter only digits in whatsapp number Field !')
    }
    else if ((!products || (products && products.length === 0)) && otherProducts.name.trim() === '')
      setError('Select product or Write additional / Other products !')
    else {
      setPdfSaving(true)
      const printableElement = document.querySelector('.printable');
      const canvas = await html2canvas(printableElement, { scale: 1.5 });

      const pdf = new jsPDF('p', 'pt', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth() - 60; // 40mm margins
      const pageHeight = pdf.internal.pageSize.getHeight() - 100; // 40mm margins

      const totalHeight = canvas.height * pdfWidth / canvas.width;
      let yPosition = 0;

      while (yPosition < totalHeight) {
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.min(pageHeight * canvas.width / pdfWidth, canvas.height - yPosition * canvas.width / pdfWidth);

        const pageContext = pageCanvas.getContext('2d');
        pageContext.drawImage(canvas, 0, yPosition * canvas.width / pdfWidth, pageCanvas.width, pageCanvas.height, 0, 0, pageCanvas.width, pageCanvas.height);

        const pageImgData = pageCanvas.toDataURL('image/jpeg', 0.75);
        pdf.addImage(pageImgData, 'JPEG', 30, 50, pdfWidth, pageCanvas.height * pdfWidth / canvas.width);
        yPosition += pageHeight;

        if (yPosition < totalHeight) {
          pdf.addPage();
        }
      }

      const pdfBlob = pdf.output('blob');
      const formData = new FormData();
      formData.append('order_pdf', pdfBlob, `${cdetail.name}_${getDate()}.pdf`);
      formData.append('order_username', cdetail.name);
      formData.append('order_house_no', cdetail.house_no);
      formData.append('order_street_no', cdetail.street_no);
      formData.append('order_colony', cdetail.colony);
      formData.append('order_city', cdetail.city);
      formData.append('order_number', cdetail.number)
      formData.append('total_price', total)
      formData.append('additional', otherProducts.name)
      formData.append('additional_total', otherProducts.total)
      formData.append('total_item', products ? products.length : 0)
      formData.append('discount', Number(d1.percentage !== 0 ? Math.floor((d1.percentage * (total + Number(otherProducts['total']))) / 100) : d1.number !== 0 ? d1.number : 0) + Number(d2.percentage !== 0 ? Math.floor((d2.percentage * ((total + Number(otherProducts['total'])) - d1.number - Math.floor((d1.percentage * (total + Number(otherProducts['total']))) / 100))) / 100) : d2.number !== 0 ? d2.number : 0))
      searchedCustomer && customerLink ? formData.append('link_with', searchedCustomer.id) : formData.append('link_with', -1)
      dispatch(saveOrderPdf({ 'bill_id': bill_id ? bill_id : -1, 'data': formData }))
    }
  }

  //  print the bill
  function print() {
    const old = document.title;
    document.title = `${cdetail.name} ${getDate()}`;
    window.print()
    document.title = old
  }

  useEffect(() => {
    // reffrence.searchInputRef.current?.addEventListener('focus', console.log('focustedd'))
    return () => {
      dispatch(setSearchedProduct(null))
      dispatch(setSearchedCustomer(null))
    }
  }, [dispatch])

  // customer searchF
  useEffect(() => {
    const q = query.toString()
    if (using === 'number' && q.length === 10) {
      searchCustomer()
    }
    else if (using === 'name' && q.length > 3) {
      searchCustomer()
    }
    else {
      setCustomerDisplay('none')
    }
  }, [query])


  useEffect(() => {
    searchedCustomer ? setCdetail({
      'name': searchedCustomer.name,
      'house_no': searchedCustomer.house_no,
      'street_no': searchedCustomer.street_no,
      'colony': searchedCustomer.colony,
      'city': searchedCustomer.city,
      'number': searchedCustomer.number
    }) : <></>
  }, [searchedCustomer])

  // runs when dukandar start making bill from Orders page
  useEffect(() => {
    dispatch(dropdown(false))
    if (bill_id) {
      getItems()
    }
  }, [bill_id])


  // get the dukanProfile for the bill
  useEffect(() => {
    dukanProfile === null ? dispatch(getDukanProfile()) : <></>
  }, [dukanProfile])

  // runs on dukandar success error, or any product that is searched
  useEffect(() => {
    if (searchedProduct) {
      products === null ? setProducts([{ ...searchedProduct, 'uniqueId': uniqueId }]) : setProducts((prev) => { return [{ ...searchedProduct, 'uniqueId': uniqueId }, ...prev] })
      setQuantities((prev) => ({ ...prev, [`uniqueId_${uniqueId}`]: 1 }));
      setTotalQuantity((prev) => { return prev + 1 })
      setSaving((prev) => { return prev + searchedProduct.mrp - searchedProduct.price })
      setTotal((prev) => { return prev + searchedProduct.price })
      setUniqueId(prev => prev + 1)
      // dispatch(setSearchedCustomer(null))
    }
    if (dukandarSuccess && dukandarSuccess.includes('Order')) {
      setPdfSaving(false)
      setSuccess(dukandarSuccess)
      // bill_id ? dispatch(updateDukanOrder({ 'page': 'billDesk', 'id': bill_id, 'username': cdetail.name, 'colony': cdetail.colony, 'city': cdetail.city, 'additional': aditionalP.name, 'total_price': total, 'additional_total': otherProducts.total, 'number': cdetail.number, 'total_item': products ? products.length : 0 })) : <></>
      dispatch(dukandarDefault())
    }
    else if (dukandarError && dukandarErrorType === 'normal') {
      setPdfSaving(false)
      setError(dukandarError)
      dispatch(dukandarDefault())
    }
  }, [dukandarSuccess, dukandarError, searchedProduct])

  return (
    <section style={{ overflowX: 'scroll', scrollbarWidth: 'none', padding: '0px 17px' }}>
      <Helmet>
        <title>Bill Desk</title>
      </Helmet>
      {
        userInfoLoading || pdfSaving ? <Loading /> :
          dukandarError === errorName['404Error'] || dukandarError === errorName.internalError ? <NoConnection /> :
            dukandarError === errorName.networkError ? <NoConnection network='true' /> :
              user === null || dukandarError === errorName.authenticationError ? <NotAuthorized path={`${path}${location.search}`} /> :
                is_dukandar === false || dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                  <div className='billdesk'>
                    <h1 className='h4 heading mb-2'>Add Products</h1>

                    <div className='search-add-products' style={{ overflow: 'visible' }}>
                      <div className='searchlist mt-0 mb-4'>
                        <div className='searchlistinput'>
                          <input ref={reffrence.searchInputRef} onKeyDown={(e) => handleKeyDown(e, reffrence.nameRef, inputFocusIndex, setInputFocusIndes, list)} className="h6 searchinput" onChange={(e) => changeInput(e)} value={search} placeholder="Search product here." autoComplete='off' />
                          <span onClick={() => clearInput()}><CrossIcon style={{ position: 'absolute', top: '4px', right: '9px', fill: 'gray', overflow: 'hidden', cursor: 'pointer', width: '15px' }} /></span>
                        </div>
                        <div className='searchlistdiv' style={{ display: display }}>
                          <div style={{ width: '100%' }}>{
                            loading === true ?
                              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}><Sloading width={25} height={30} color='red' /> </div> :
                              list && list.map((item, key) => {
                                return (
                                  <div className="searchlistvalue" key={key} onClick={() => getProduct(item)} style={{ background: key === inputFocusIndex ? '#ffff0075' : 'transparent' }}>
                                    <h1 className="h6" key={key}>{item} </h1>
                                    <hr style={{ margin: '0px' }} />
                                  </div>
                                )
                              })}
                          </div>
                        </div>
                      </div>

                      <h1 className='h5 d-flex' style={{ alignItems: 'center', gap: '10px', position: 'absolute', zIndex: 10, top: '122px' }}>Product  <span className='primary-button' onClick={() => addAdditionalProduct()} style={{ width: 'fit-content', margin: '0', padding: '5px 6px', cursor: 'pointer' }}> <PlusIcon className='' style={{ width: '13px' }} /> </span></h1>
                      <div className='row mt-1 mb-5 addproduct' onKeyDown={(e) => shiftEnter(e)} id='addproduct' style={{ overflow: 'visible', width: '98%' }} >
                        <h6 style={{ fontSize: '0.75rem', color: 'red', marginTop: '-10px' }}>Shift + Enter to add </h6>
                        <div className='col-4 ps-0' style={{ overflow: 'visible', position: 'relative' }}>
                          <div className='h6 d-flex' style={{ padding: '0 0 0 15px', alignItems: 'center', gap: '10px' }}>Name : <input onKeyDown={(e) => handleKeyDown(e, reffrence.mrpRef, adtionalFocusIndex, setAditionalFocusIndex, aditionalP.list)} ref={reffrence.nameRef} value={aditionalP.name} className='h6' style={{ flex: '1' }} onChange={(e) => changeAditionalP(e)} name='name' placeholder='Toor Daal 375gm' autoComplete='off' /></div>
                          <div className='searchlistdiv' style={{ display: aditionalP.display, left: '60px', width: '80%' }}>
                            <div style={{ width: '100%' }}>{
                              aditionalP.loading === true ?
                                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}><Sloading width={25} height={30} color='red' /> </div> :
                                aditionalP.list && aditionalP.list.map((item, key) => {
                                  return (
                                    <div className="searchlistvalue" key={key} onClick={(e) => selectAditionalP(item)} style={{ background: key === adtionalFocusIndex ? '#ffff0075' : 'transparent' }}>
                                      <h1 className="h6" key={key}>{item} </h1>
                                      <hr style={{ margin: '0px' }} />
                                    </div>
                                  )
                                })}
                            </div>
                          </div>
                        </div>
                        <div className='h6 col-2 d-flex' style={{ padding: '0 0 0 10px', alignItems: 'center', gap: '10px' }}>MRP : <input onKeyDown={(e) => nextOnEnter(e, reffrence.priceRef)} ref={reffrence.mrpRef} type='number' value={aditionalP.mrp} className='h6' style={{ flex: '1' }} onChange={(e) => changeAditionalP(e)} name='mrp' placeholder='120' /></div>
                        <div className='h6 col-2 d-flex' style={{ padding: '0 0 0 10px', alignItems: 'center', gap: '10px' }}>Price : <input onKeyDown={(e) => nextOnEnter(e, reffrence.offerRef)} ref={reffrence.priceRef} type='number' value={aditionalP.price} className='h6' style={{ flex: '1' }} onChange={(e) => changeAditionalP(e)} name='price' placeholder='100' /></div>
                        <div className='h6 col-4 d-flex' style={{ padding: '0 0 0 10px', alignItems: 'center', gap: '10px' }}>Offer : <input onKeyDown={(e) => nextOnEnter(e, reffrence.customerRef)} ref={reffrence.offerRef} value={aditionalP.offer} className='h6' style={{ flex: '1' }} onChange={(e) => changeAditionalP(e)} name='offer' placeholder='1 किलो तुअर दाल पर 1 साबून मुफ़्त' /></div>
                      </div>
                    </div>

                    <div className='searchlist mt-3 mb-3 customersearch'>
                      <div className='searchlistinput'>
                        <input ref={reffrence.customerRef} onKeyDown={(e) => handleKeyDown(e, reffrence.searchInputRef, customerFocusIndex, setCustomerFocusIndex, customer)} className="h6" type={using === 'number' ? 'number' : 'text'} onChange={(e) => setQuery(e.target.value)} value={query} placeholder="Search customer here." />
                        <span onClick={() => changeUsing('name')}><PhoneIcon className={using === 'number' ? 'd-block' : 'd-none'} /></span>
                        <svg className={using === 'name' ? 'd-block' : 'd-none'} onClick={() => changeUsing('number')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256l0 32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32l0 80 0 32c0 17.7 14.3 32 32 32s32-14.3 32-32l0-32c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" /></svg>
                      </div>
                      <div className="searchlistdiv" style={{ display: customerDisplay }}>
                        <div style={{ width: '100%' }}>{
                          customerLoading === true ?
                            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}><Sloading width={25} height={30} color='red' /> </div> :
                            customer && customer.map((item, key) => {
                              return (
                                <div className="searchlistvalue" key={key} onClick={() => select(item)} style={{ background: key === customerFocusIndex ? '#ffff0075' : 'transparent' }}>
                                  <h1 className="h6" key={key}>{item.name}</h1>
                                  <hr style={{ margin: '0px' }} />
                                </div>
                              )
                            })
                        }
                        </div>
                      </div>
                    </div>

                    <h1 className='heading h4 mt-1'>Bill Desk</h1>
                    <div className='bill printable' id="my-table">

                      <div className='row shop-customer-detail'>
                        <div className='col-4 col-lg-5 col-xl-6'>
                          <div className='mt-2 h6 d-flex' style={{ alignItems: 'center' }}><b className='me-2'>Bill Id : </b> MKB-{bill_id}</div>
                          <h1 className='h6 mt-2'><span style={{ fontWeight: 'bold' }}>Shop : </span> {dukanProfile?.name} </h1>
                          <h1 className='h6 mt-2'><span style={{ fontWeight: 'bold' }}>Add.  : </span> {dukanProfile?.address}, {dukanProfile?.city}</h1>
                          <h1 className='h6 mt-2'><span style={{ fontWeight: 'bold' }}>Owner :</span> {dukanProfile?.owner}</h1>
                          <h1 className='h6 d-flex gap-2 mt-2' style={{ alignItems: 'center' }}><PhoneIcon style={{ 'width': '15px' }} /> : {dukanProfile?.phone_number} <WhatsappIcon className='me-1' /> : {dukanProfile?.whatsapp_number}</h1>
                        </div>
                        <div className='col-8 col-lg-7 col-xl-6'>
                          <div className='mt-2 h6 d-flex gap-2' style={{ alignItems: 'center' }}><b>Customer : </b> <input onKeyDown={(e) => nextOnEnter(e, reffrence.chouseRef)} ref={reffrence.cnameRef} value={cdetail.name} onChange={(e) => setCdetail((prev) => { return { ...prev, 'name': e.target.value } })} ></input></div>
                          <div className='row'>
                            <div className='col-4 col-lg-4'>
                              <div className='mt-2 h6 d-flex gap-2' style={{ alignItems: 'center' }}><b>House No. : </b><input type='number' onKeyDown={(e) => nextOnEnter(e, reffrence.cstreetRef)} ref={reffrence.chouseRef} value={cdetail.house_no} onChange={(e) => setCdetail((prev) => { return { ...prev, 'house_no': e.target.value } })}></input></div>
                            </div>
                            <div className='col-8 col-lg-8'>
                              <div className='mt-2 h6 d-flex gap-2' style={{ alignItems: 'center' }}><b>Street No. / Ward No. : </b><input type='number' onKeyDown={(e) => nextOnEnter(e, reffrence.ccolonyRef)} ref={reffrence.cstreetRef} value={cdetail.street_no} onChange={(e) => setCdetail((prev) => { return { ...prev, 'street_no': e.target.value } })}></input></div>
                            </div>
                          </div>
                          <div className='mt-2 h6 d-flex gap-2' style={{ alignItems: 'center' }}><b>Colony : </b> <input onKeyDown={(e) => nextOnEnter(e, reffrence.ccityRef)} ref={reffrence.ccolonyRef} value={cdetail.colony} onChange={(e) => setCdetail((prev) => { return { ...prev, 'colony': e.target.value } })} ></input></div>
                          <div className='row'>
                            <div className='col-6 col-lg-8'>
                              <div className='mt-2 h6 d-flex gap-2' style={{ alignItems: 'center' }}><b>City : </b><input onKeyDown={(e) => nextOnEnter(e, reffrence.cnumberRef)} ref={reffrence.ccityRef} value={cdetail.city} onChange={(e) => setCdetail((prev) => { return { ...prev, 'city': e.target.value } })}></input></div>
                            </div>
                            <div className='col-3 col-lg-4'>
                              <div className='mt-2 h6 d-flex gap-1' style={{ alignItems: 'center' }}><WhatsappIcon /> : <input type='number' onKeyDown={(e) => nextOnEnter(e, reffrence.cnameRef)} ref={reffrence.cnumberRef} value={cdetail.number} onChange={(e) => setCdetail((prev) => { return { ...prev, 'number': e.target.value } })}></input></div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6'>
                              <div className='mt-2 h6 d-flex' style={{ alignItems: 'center' }}><b className='me-2'>Time : </b> {getTime()}</div>
                            </div>
                            <div className='col-6'>
                              <div className='mt-2 h6 d-flex' style={{ alignItems: 'center' }}><b className='me-2'>Date : </b> {getDate()}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <table style={{ width: '100%', marginTop: '20px' }}>
                        <thead className='tableHeading'>
                          <tr>
                            <th className='h5' style={{ color: 'green', width: '2%' }}>No.</th>
                            <th className='h5' style={{ color: 'green', width: '30%' }}>Product</th>
                            <th className='h5' style={{ color: 'green', width: '22%' }}>Offer</th>
                            <th className='h5' style={{ color: 'green', width: '8%' }}>MRP</th>
                            <th className='h5' style={{ color: 'green', width: '9%' }}>Our Price</th>
                            <th className='h5' style={{ color: 'green', width: '8%' }}>Quantity</th>
                            <th className='h5' style={{ color: 'green', width: '9%' }}>Saving</th>
                            <th className='h5' style={{ color: 'green', width: '9%' }}>Total</th>
                            <th className='h5' style={{ color: 'green', width: '3%' }}>Del</th>
                          </tr>
                        </thead>

                        {products ? <>
                          {
                            products.map((item, key) => {
                              return (
                                <tbody key={key}>
                                  <BillDeskItem reffrence={reffrence.searchInputRef} index={key + 1} item={item} quantity={quantities[`uniqueId_${item.uniqueId}`]} setQuantity={handleQuantityChange} setTotal={setTotal} setSaving={setSaving} setTotalQuantity={setTotalQuantity} removeProduct={removeProduct} />
                                </tbody>
                              )
                            })
                          }
                        </> : <></>}

                        <tbody className='row-total'>
                          <tr >
                            <td className='h6 pt-1 pb-1'></td>
                            <td className='h5 pt-1 pb-1' style={{ color: 'green' }}>Item Total</td>
                            <td className='h6 pt-1 pb-1'></td>
                            <td className='h6 pt-1 pb-1'></td>
                            <td className='h6 pt-1 pb-1'></td>
                            <td className='h6 pt-1 pb-1 me-5' style={{ fontWeight: 'bold' }}>{totalQuantity}</td>
                            <td className='h6 pt-1 pb-1' style={{ fontWeight: 'bold' }}>{saving}</td>
                            <td className='h6 pt-1 pb-1' style={{ fontWeight: 'bold' }}>{total}</td>
                          </tr>
                        </tbody>
                      </table>


                      {
                        otherProducts.show ?
                          <table className='w-100 mb-2 other-products' >
                            <thead>
                              <tr>
                                <th style={{ width: '88%' }}><textarea ref={reffrence.otherPnameRef} onKeyDown={(e) => handleKeyDown(e, reffrence.otherPtotalRef)} style={{ borderBottom: 0, scrollbarWidth: 'none' }} className='h6' rows='1' onChange={(e) => changeOtherProducts(e)} name='name' value={otherProducts.name} placeholder='Other products like 50gm jeera, 25 ki chai patti, 20gm haldi, 30 ki toor daal, teen paav pohe' /></th>
                                <th style={{ width: '9%' }}><input ref={reffrence.otherPtotalRef} onKeyDown={(e) => handleKeyDown(e, reffrence.otherPnameRef)} style={{ textAlign: 'center' }} type='number' onChange={(e) => changeOtherProducts(e)} name='total' value={otherProducts.total} /></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Sub Total</td>
                                <td>{total + Number(otherProducts.total)} </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table> : <></>
                      }


                      <div className='mt-4 bill-discount'>
                        {d1.name !== '' && d1.show ?
                          <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                            <h1 className='h6 mb-2'>* {d1.name} : {d1.percentage !== 0 ? `${d1.percentage} %` : d1.number !== 0 ? `${d1.number} Rs.` : ''}</h1>
                            <h1 className='h6' style={{ marginRight: '6.3%', fontWeight: 'bold' }}>{d1.percentage !== 0 ? `- ${Math.floor((d1.percentage * (total + Number(otherProducts['total']))) / 100)}` : d1.number !== 0 ? `- ${d1.number}` : ''} </h1>
                          </div> : <></>
                        }
                        {d2.name !== '' && d2.show ?
                          <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                            <h1 className='h6 mb-2'>* {d2.name} : {d2.percentage !== 0 ? `${d2.percentage} %` : d2.number !== 0 ? `${d2.number} Rs.` : ''}</h1>
                            <h1 className='h6' style={{ marginRight: '6.3%', fontWeight: 'bold' }}>{d2.percentage !== 0 ? `- ${Math.floor((d2.percentage * ((total + Number(otherProducts['total'])) - d1.number - Math.floor((d1.percentage * (total + Number(otherProducts['total']))) / 100))) / 100)}` : d2.number !== 0 ? `- ${d2.number}` : ''} </h1>
                          </div> : <></>
                        }

                        <div style={{ float: 'right', marginRight: '5%' }}>Total to pay : {(total + Number(otherProducts.total)) - d1.number - d2.number - Math.floor((d1.percentage * (total + Number(otherProducts['total']))) / 100) - Math.floor((d2.percentage * ((total + Number(otherProducts['total'])) - d1.number - Math.floor((d1.percentage * (total + Number(otherProducts['total']))) / 100)) / 100))} /-</div>
                      </div>
                    </div>

                    {
                      searchedCustomer ?
                        <div className='d-flex gap-2'>
                          <input type='checkbox' checked={customerLink} onChange={(e) => setCustomerLink(!customerLink)} style={{ width: 'fit-content' }} />
                          <label>Also link with customer - Id : {searchedCustomer.id}, Name :  {searchedCustomer.name}</label>
                        </div> : <></>
                    }
                    <div className='buttons' style={{ textAlign: 'center' }}>
                      <button onClick={() => print()} className='h6 secondry-button mt-4 mb-3 ps-4 pe-4 pt-1 pb-1' style={{ width: 'fit-content' }} >Print</button>
                      <button onClick={() => setDefault()} className='h6 secondry-button mt-4 mb-3 ms-5 ps-4 pe-4 pt-1 pb-1' style={{ width: 'fit-content' }} >New</button>
                      <button onClick={() => save()} className='h6 secondry-button mt-4 mb-3 ms-5 ps-4 pe-4 pt-1 pb-1' style={{ width: 'fit-content' }} >Save</button>
                    </div>
                    <hr />

                    <div className='d-flex gap-3 mt-2' style={{ alignItems: 'center' }}>
                      <input type='checkbox' checked={otherProducts.show} style={{ width: 'fit-content' }} name='show' onChange={(e) => changeOtherProducts(e)} />
                      <label className='h6 me-4'>Show Other Products</label>
                    </div>

                    <div className='add-discount'>
                      <div className='billDiscountInput row mt-2'>
                        <div className='col-8 d-flex' style={{ alignItems: 'center', gap: '10px' }} >
                          <input type='checkbox' checked={d1.show} style={{ width: 'fit-content' }} name='show' onChange={(e) => changeD1(null, e)} />
                          <span style={{ color: 'red' }} className='h6'>Discount 1 Name : </span> <input onKeyDown={(e) => nextOnEnter(e, reffrence.d1NumberRef)} ref={reffrence.d1NameRef} className='h6' style={{ flex: '1' }} type='text' name='name' onChange={(e) => changeD1(null, e)} value={d1.name} placeholder='name' />
                        </div>
                        <div className='col-2'>
                          <input onKeyDown={(e) => nextOnEnter(e, reffrence.d1PercentageRef)} ref={reffrence.d1NumberRef} className='h6' style={{ flex: '1' }} type='number' name='number' onChange={(e) => changeD1('percentage', e)} value={d1.number === 0 ? '' : d1.number} placeholder='rupay' />
                        </div>
                        <div className='col-2'>
                          <input onKeyDown={(e) => nextOnEnter(e, reffrence.d2NameRef)} ref={reffrence.d1PercentageRef} className='h6' style={{ flex: '1' }} type='number' name='percentage' onChange={(e) => changeD1('number', e)} value={d1.percentage === 0 ? '' : d1.percentage} placeholder='percentatge' />
                        </div>
                        <div className='col-8 d-flex' style={{ alignItems: 'center', gap: '10px' }} >
                          <input type='checkbox' checked={d2.show} style={{ width: 'fit-content' }} name='show' onChange={(e) => changeD2(null, e)} />
                          <span style={{ color: 'red' }} className='h6'>Discount 2 Name : </span> <input onKeyDown={(e) => nextOnEnter(e, reffrence.d2NumberRef)} ref={reffrence.d2NameRef} className='h6' style={{ flex: '1' }} type='text' name='name' onChange={(e) => changeD2(null, e)} value={d2.name} placeholder='name' />
                        </div>
                        <div className='col-2'>
                          <input onKeyDown={(e) => nextOnEnter(e, reffrence.d2PercentageRef)} ref={reffrence.d2NumberRef} className='h6' style={{ flex: '1' }} type='number' name='number' onChange={(e) => changeD2('percentage', e)} value={d2.number === 0 ? '' : d2.number} placeholder='rupay' />
                        </div>
                        <div className='col-2'>
                          <input onKeyDown={(e) => nextOnEnter(e, reffrence.d1NameRef)} ref={reffrence.d2PercentageRef} className='h6' style={{ flex: '1' }} type='number' name='percentage' onChange={(e) => changeD2('number', e)} value={d2.percentage === 0 ? '' : d2.percentage} placeholder='percentatge' />
                        </div>
                      </div>
                    </div>
                    {
                      error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
                    }

                  </div>
      }

    </section>
  )
}

export default BillDesk;