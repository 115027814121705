import React from "react";

function SmallLogo() {
    return (
        <div className='smalllogo mt-4 mb-4'>
                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} className="img-fluid" alt='mahajan kirana logo'/>
        </div>
    )
}

export default SmallLogo;