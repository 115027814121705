import React, { useEffect, useState, useRef } from 'react'
import { CrossIcon, ErrorUp, pattern, scrollFocus, SuccessUp } from '../base/index';
import { useSelector, useDispatch } from 'react-redux';
import { addDukanCategory, dukandarDefault } from '../redux/DukandarSlice';

function AddDukanCategory() {
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [category, setCategory] = useState([])
  const [display, setDisplay] = useState('none')
  const { dukandarError, dukandarSuccess, dukanAllCategory } = useSelector((state) => state.dukandar)
  const [data, setData] = useState({
    'name': '',
    'image': null
  })
  const nameRef = useRef(null)

  function change(e) {
    const { name, value, files } = e.target
    if (name === 'name' && value.length > 0) {
      setDisplay('flex')
      const ne = dukanAllCategory.filter((item) => {
        return item.toLowerCase().includes(value.toLowerCase())
      })
      setCategory(ne)
    }
    else { setDisplay('none') }
    setData((prev) => {
      return {
        ...prev,
        [name]: files ? files.length > 0 ? files[0] : null : value
      }
    })
  }

  console.log(data)
  function selectCategory(item) {
    setDisplay('none')
    setData((prev) => {
      return { ...prev, 'name': item }
    })
  }

  function clear() {
    setDisplay('none')
    setData((prev) => {
      return { ...prev, 'name': '' }
    })
  }
  function add() {
    const trimed = data.name.replace(/\s+/g, '')
    if (trimed === '') {
      setError('Enter Category Name !')
      scrollFocus(nameRef)
    }
    else if (!pattern.categoryProductPattern.test(trimed)) {
      setError(`Use only ' a-z A-Z 0-9 | () & * '  for category name !`)
      scrollFocus(nameRef)
    }
    else if (!data.image) {
      setError('Select Category Image !')
    }
    else if (data.image && !data.image.length > 0 && data.image.size > 4 * 1024 * 1025) {
      setError('Image size should be less then 4 MB !')
    }
    else if (data.image && !data.image.type.includes('image')) {
      setError('Please select only image !')
    }
    else {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key])
      }
      dispatch(addDukanCategory({ 'data': formData }))
    }
  }

  useEffect(() => {
    setCategory(dukanAllCategory)
  }, [dukanAllCategory])


  useEffect(() => {
    if (dukandarError !== null) {
      dukandarError.toLowerCase().includes('image') ?
        setError('Upload a valid image !') : setError(dukandarError)
      dispatch(dukandarDefault())
    }
    else if (dukandarSuccess !== null) {
      setSuccess(dukandarSuccess)
      dispatch(dukandarDefault())
    }
  }, [dukandarError, dukandarSuccess])


  return (
    <div className='container'>
      <div style={{ height: '80vh' }}>
        <div className='heading h4 mt-4 mb-5'>Add Dukan Category</div>

        <div className='searchlist mt-4'>
          <div className='searchlistinput' style={{ width: '100%' }}>
            <label className='h5' htmlFor="">Category Name : </label>
            <input ref={nameRef} className="h6" type="text" name='name' value={data.name} onChange={(e) => change(e)} placeholder="Washing Powder 1Kg" autoComplete='off' />
            <span onClick={() => clear()} style={{ position: 'absolute', right: '9px', overflow: 'hidden', cursor: 'pointer' }}><CrossIcon style={{ fill: 'gray' }} /></span>
          </div>          {
            category.length > 0 ?
              <div className='searchlistdiv' style={{ display: display, top: '52px', left: '0', maxHeight: '270px', width: '260px' }}>
                <div style={{ width: '100%' }}>{
                  category.map((item, key) => {
                    return (
                      <div className="searchlistvalue" key={key} onClick={() => selectCategory(item)}>
                        <h1 className="h6" key={key}>{item} </h1>
                        <hr style={{ margin: '0px' }} />
                      </div>
                    )
                  })}
                </div>
              </div> : <></>
          }
        </div>
        <h6 style={{ fontSize: '12px', color: 'red', marginTop: '5px' }}>Use only ' a-z A-Z 0-9 | () & * '  for category name</h6>


        <label className='h5 mt-2 mb-2' htmlFor="">Category Image  : </label>
        <input className='h6' type="file" name='image' style={{ borderBottom: 'none', display: 'flex', width: 'fit-content' }} onChange={(e) => change(e)} />
        <h6 style={{ fontSize: '0.74rem', color: 'red', position: 'absolute' }}>Image size : 4 MB or less</h6>
        <button className='secondry-button h6 mt-5' style={{ width: 'fit-content', padding: '5px 35px' }} onClick={() => add()}>Add</button>
        {
          error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
        }
      </div>
    </div>
  )
}

export default AddDukanCategory