import React from 'react'
import { NavLink } from 'react-router-dom'
import { CartIcon, CategoryIcon, DiscountIcon, HomeIcon, OrderIcon, ShopIcon, CustomerIcon, BilldeskIcon, ProductIcon } from '../base/index'
import { useSelector } from 'react-redux'

function BottomTab() {
    const total_item = useSelector((state) => state.userInfo.total_item)
    const is_dukandar = useSelector((state) => state.userInfo.is_dukandar)

    return (
        <div className='bottomTab d-sm-none'>
            {
                is_dukandar === false ?
                    <>
                        <NavLink to={'/'} className='link smallText'><HomeIcon style={{ width: '26px' }} /><span>Home</span></NavLink>
                        <NavLink to={'/category/'} className='link smallText'><CategoryIcon style={{ width: '26px' }} /><span>Category</span></NavLink>
                        <NavLink to={'/myOrders/'} className='link smallText'><OrderIcon style={{ width: '19.3px' }} /><span> Orders</span></NavLink>
                        <NavLink to={'/product/SnapNShop/'} className='link smallText'><DiscountIcon style={{ width: '27px' }} /><span> SNS</span></NavLink>
                        <NavLink to={'/user/apniDukan/'} className='link smallText'><ShopIcon style={{ width: '28px' }} /><span>Dukan</span></NavLink>
                        <NavLink to={'/cartItem/'} className='link smallText position-relative'><CartIcon style={{ width: '26px' }} /><span> Cart</span><span className='itemCount'>{total_item}</span></NavLink>
                    </> :
                    <>
                        <NavLink to={'/'} className='link smallText'><HomeIcon style={{ width: '26px' }} /><span>Home</span></NavLink>
                        {/* <NavLink to={'/dukandar/category/'} className="link smallText" ><CategoryIcon style={{ width: '26px' }} /><span>Category</span></NavLink> */}
                        <NavLink to={'/dukandar/customers/'} className="link smallText" ><CustomerIcon style={{ width: '29px' }} /><span>Customers</span></NavLink>
                        <NavLink to={'/dukandar/profile/'} className="link smallText" ><ShopIcon style={{ width: '28px' }} /><span>Dukan</span></NavLink>
                        <NavLink to={'/dukandar/orders/'} className="link smallText" ><OrderIcon style={{ width: '19.3px' }} /><span>Orders</span></NavLink>
                        {/* <NavLink to={'/dukandar/profile/'} className="link smallText" ><ShopIcon style={{ width: '28px' }} /><span>Dukan</span></NavLink> */}
                        <NavLink to={'/dukandar/billDesk/'} className="link smallText" ><BilldeskIcon style={{ width: '19px' }} /><span>Bill Desk</span></NavLink>
                    </>
            }
        </div>
    )
}

export default BottomTab