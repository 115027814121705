import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dukandarDefault, addDukanProduct } from "../redux/DukandarSlice";
import { ErrorUp, SuccessUp, pattern, scrollFocus, nextOnEnter} from '../base/index'

function AddDukanProduct({data, setData}) {
  const dispatch = useDispatch()
  const { dukandarError, dukandarSuccess, dukanAllCategory } = useSelector((state) => state.dukandar)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  
  const refrence = {
    categoryRef : useRef(null),
    productRef : useRef(null),
    mrpRef : useRef(null),
    priceRef : useRef(null),
    offerRef : useRef(null),
    tagRef : useRef(null),
    imageRef : useRef(null),
  }


  function change(e) {
    const {name, value, files} = e.target
    setData((prev)=>{
      return {
        ...prev, [name] : files ? files.length > 0 ? files[0] : null : value
      }
    })
  }
console.log('addd')

  function add() {
    const name_trimed = data.name.replace(/\s+/g, '')
    const tag_trimed = data.tag.replace(/\s+/g, '')
    if (data.category === '' || data.category === 'Select Category'){
      scrollFocus(refrence.categoryRef)
      setError('Please select category !')
    }
    else if (name_trimed === '') {
      scrollFocus(refrence.productRef)
      setError('Enter Product Name !')
    }
    else if (!pattern.categoryProductPattern.test(name_trimed)) {
      scrollFocus(refrence.productRef)
      setError(`Use only ' a-z A-Z 0-9 | () & * '  for product name !`)
    }
      else if (Number(data.mrp) <= 0 || Number(data.mrp) < Number(data.price)) {
        scrollFocus(refrence.mrpRef)
        setError('Enter valid product MRP !')
      }
      else if (Number(data.price) <= 0 || Number(data.price) > Number(data.mrp)){
        scrollFocus(refrence.priceRef)
        setError('Enter valid product price !')
      }
    else if (tag_trimed === '') {
      scrollFocus(refrence.tagRef)
      setError('Enter the product tags !')
    }
    else if (data.image === null) {
      setError('Please select product image !')
    }
    else if (!data.image.length > 0 && data.image.size > 4 * 1024 * 1025 ) 
      setError('Image size should be less then 4 MB')
    else if (!data.image.length > 0 &&  !data.image.type.includes('image')){
      setError('Please select only image !')
    }
    else {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key])
      }
      dispatch(addDukanProduct({ 'data': formData }))
    }
  }


  useEffect(() => {
    if (dukandarError !== null && !dukandarError.toLowerCase().includes('no product')) {
      dukandarError?.toLowerCase().includes('image') ? setError('Upload valid image !') : setError(dukandarError)
      dispatch(dukandarDefault())
    }
    else if (dukandarSuccess !== null) {
      setSuccess(dukandarSuccess)
      dispatch(dukandarDefault())
    }
  }, [dukandarError, dukandarSuccess])


  return (
    <div className="container">
            <div className='heading h4 mt-4 mb-3'>Add Dukan Product</div>
            <label className='h5 mt-4 me-3' htmlFor="">Category : </label>
            <select ref = {refrence.categoryRef} onKeyDown = {(e)=>nextOnEnter(e, refrence.productRef)} name='category' style={{border : '1px solid black'}} value={data.category} onChange={(e) => change(e)}>
              <option value='Select Category'>Select Category</option>
              {
                dukanAllCategory.map((item, key) => {
                  return <option key={key} value={item}>{item}</option>
                })
              }
            </select> <br />
            <label className='h5 mt-4' htmlFor="">Product Name : </label>
            <input ref = {refrence.productRef} onKeyDown = {(e) => nextOnEnter(e, refrence.mrpRef)}className='h6' type="text" name='name' value={data.name} onChange={(e) => change(e)} placeholder='Wheel Sabun' />
            <h6 style={{fontSize : '12px', color : 'red', marginTop : '5px'}}>Use only ' a-z A-Z 0-9 | () & * '  for product name !</h6>
            <label className='h5 mt-3' htmlFor="">MRP : </label>
            <input ref = {refrence.mrpRef} onKeyDown = {(e) => nextOnEnter(e, refrence.priceRef)}className='h6' type="number" name='mrp' value={data.mrp} onChange={(e) => change(e)} />
            <label className='h5 mt-4' htmlFor="">Price : </label>
            <input ref = {refrence.priceRef} onKeyDown = {(e) => nextOnEnter(e, refrence.offerRef)}className='h6' type="number" name='price' value={data.price} onChange={(e) => change(e)} />
            <label className='h5 mt-4' htmlFor="">Offer : </label>
            <input ref = {refrence.offerRef} onKeyDown = {(e) => nextOnEnter(e, refrence.tagRef)}className='h6' type="text" name='offer' value={data.offer} onChange={(e) => change(e)} placeholder='5 ख़रीदे सिर्फ 45 में ' />
            <label className="h5 mt-4 me-3" htmlFor="">Available in loose : </label>
            <input type='checkbox' className='h6' name='loose' checked={data.is_loose} onChange={(e) => change(e)} style={{ width: 'fit-content', marginRight: '10px' }} /> <br />
            <label className='h5 mt-4' htmlFor="">Tag : </label>
            <textarea ref = {refrence.tagRef} onKeyDown={(e)=>nextOnEnter(e, refrence.categoryRef)} rows={4} className='h6' type="" name='tag' value={data.tag} onChange={(e) => change(e)} placeholder='wheel sabun kapde dhona cloat soap व्हील साबुन कपडे धोने कपड़ा साबुन' />
            <label className='h5 mt-4' htmlFor="">Image  : </label>
            <input ref = {refrence.imageRef} className='h6' type="file" name='image' style={{ borderBottom: 'none' }} onChange={(e) => change(e)} />
            <h6 style={{fontSize : '0.74rem', color : 'red', position : 'absolute'}}>Image size : 4 MB or less</h6>            
            <button className='secondry-button h6 mt-5 me-3' style={{ width: 'fit-content', padding: '5px 35px' }} onClick={()=>setData({'category': '', 'name': '', 'mrp': '', 'price': '', 'image': null, 'tag': '', 'offer': ''})}>New</button>
            <button className='secondry-button h6 mt-5' style={{ width: 'fit-content', padding: '5px 35px' }} onClick={() => add()}>Add</button>
            {
              error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
            }
    </div>
  )
}

export default AddDukanProduct;