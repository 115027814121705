import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { url, Sloading, getToken } from '../base'
import { updateLinkCustomerInDuaknOrder } from '../redux/DukandarSlice'

function LinkCustomer({ linkedcustomer, order_id, setCustomerDetailNOrder }) {
    const [checked, setChecked] = useState(false)
    const [using, setUsing] = useState('number')
    const [query, setQuery] = useState('')
    const [customer, setCustomer] = useState(null)
    const [loading, setLoading] = useState(false)
    const [display, setDisplay] = useState('none')
    const [linkWith, setLinkWith] = useState(null)
    const [msg, setMsg] = useState(null)
    const dispatch = useDispatch()

    function select(item) {
        console.log(item)
        setDisplay('none')
        setLinkWith(item)
    }

    async function search() {
        setDisplay('flex')
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/dukandar/searchCustomer/?using=${using}&query=${query}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (request.data && request.data.data) {
                setCustomer(request.data.data)
                setDisplay('flex')
            }
            else if (request.data && request.data.error_message) {
                setDisplay('none')
            }
        }
        catch (error) {
            setDisplay('none')
        }
    }

    function changeUsing(using) {
        setQuery('')
        setUsing(using)
    }

    async function linkOrderWithCustomer() {
        setMsg(null)
        if (linkWith) {
            if (checked) {
                setLoading(true)
                try {
                    const token = getToken()
                    const request = await axios.put(`${url}/api/dukandar/linkOrderWithCustomer/`, { 'order_id': order_id, 'customer_id': linkWith ? linkWith.id : -1 }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    if (request.data && request.data.success_message) {
                        setMsg(request.data.success_message)
                        setCustomerDetailNOrder ? setCustomerDetailNOrder((prev) => {
                            return prev.map((item, index) => {
                                return item.id === order_id ? { ...request.data.data } : { ...item }
                            })
                        }) : <></>
                        setChecked(false)
                        dispatch(updateLinkCustomerInDuaknOrder({ 'data' : request.data.data }))
                    }
                    else {
                        setChecked(false)
                        setMsg(request.data.error_message)
                    }
                    setLinkWith(null)
                    setLoading(false)
                }
                catch (error) {
                    setChecked(false)
                    setLoading(false)
                    console.log(error)
                    setMsg('Oops! can not perform operation, Try again.')
                }
            }
            else {
                setMsg('Please select check box !')
            }
        }
        else {
            setMsg('Select customer !')
        }
    }

    async function disLinkOrderWithCustomer() {
        setMsg(null)
        if (linkedcustomer) {
            if (checked) {
                setLoading(true)
                try {
                    const token = getToken()
                    const request = await axios.put(`${url}/api/dukandar/disLinkOrderWithCustomer/`, { 'order_id': order_id }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    console.log(request)
                    if (request.data && request.data.success_message) {
                        setMsg(request.data.success_message)
                        setCustomerDetailNOrder ? setCustomerDetailNOrder((prev) => {
                            return prev.map((item, index) => {
                                return item.id === order_id ? { ...request.data.data } : { ...item }
                            })
                        }) : <></>
                        dispatch(updateLinkCustomerInDuaknOrder({'data' : request.data.data}))
                    }
                    else {
                        setMsg(request.data.error_message)
                    }
                    setLoading(false)
                    setChecked(false)
                }
                catch (error) {
                    console.log(error)
                    setLoading(false)
                    setChecked(false)
                    setMsg('Oops! can not perform operation, Try again.')
                }
            }
            else
                setMsg('Please select check box !')
        }
        else
            setMsg('Select customer !')
    }

    useEffect(() => {
        const q = query.toString()
        if (using === 'number' && q.length === 10) {
            search()
        }
        else if (using === 'name' && q.length > 3) {
            search()
        }
        else {
            setDisplay('none')
        }
    }, [query])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setMsg(null)
        }, [2000])

        return () => clearTimeout(timeout)
    }, [msg])

    return (
        <div class="modal fade linkcustomer" id={`linkcustomer${order_id}`} >
            <div class="modal-dialog" style={{ overflow: 'visible' }}>
                <div class="modal-content pb-2" style={{ overflow: 'visible' }}>
                    <div class="modal-header">
                        <h1 class="modal-title h6" id="exampleModalLabel">Link this order with a customer.</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style={{ overflow: 'visible' }}>
                        <div className='searchlist mt-2 customersearch'>
                            <div className='searchlistinput'>
                                <input className="h6" type={using === 'number' ? 'number' : 'text'} onChange={(e) => setQuery(e.target.value)} value={query} placeholder="Search customer here." />
                                <svg className={using === 'number' ? 'd-block' : 'd-none'} onClick={() => changeUsing('name')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                                <svg className={using === 'name' ? 'd-block' : 'd-none'} onClick={() => changeUsing('number')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256l0 32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32l0 80 0 32c0 17.7 14.3 32 32 32s32-14.3 32-32l0-32c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" /></svg>
                            </div>
                            <div className="searchlistdiv" style={{ display: display }}>
                                <div style={{ width: '100%' }}>{
                                    loading === true ?
                                        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}><Sloading width={25} height={30} color='red' /> </div> :
                                        customer && customer.map((item, key) => {
                                            return (
                                                <div className="searchlistvalue" key={key} onClick={() => select(item)}>
                                                    <h1 className="h6" key={key}>{item.name}</h1>
                                                    <hr style={{ margin: '0px' }} />
                                                </div>
                                            )
                                        })
                                }
                                </div>
                            </div>
                        </div>

                        <div className='heading h6 mb-2 mt-2'>Now linking with</div>
                        <div className='h6' style={{ textAlign: 'center' }}>Customer : {linkWith ? `MKU-${linkWith.id},  ${linkWith.name}` : 'Search and Select Customer'}</div>
                        <div className='heading h6 mb-2 mt-2'>Currently linked with</div>
                        <div className='h6' style={{ textAlign: 'center' }}>Customer : {linkedcustomer ? linkedcustomer : 'Not Linked'}</div>
                        <div className='d-flex mt-2 mb-2 gap-3' style={{ justifyContent: 'center' }}>
                            <label>Really want to perform action ? </label>
                            <input type='checkbox' checked={checked} onClick={() => setChecked(!checked)} style={{ width: 'fit-content' }} />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="secondry-button h6 m-0 pt-1 pb-1" style={{ width: '80px' }} disabled={linkedcustomer && !loading ? false : true} onClick={() => disLinkOrderWithCustomer()}>{loading ? <Sloading color='black' /> : 'Dislink'} </button>
                        <button type="button" class="secondry-button h6 m-0 pt-1 pb-1 ms-2" style={{ width: '60px' }} disabled={loading ? true : false} onClick={() => linkOrderWithCustomer()}>{loading ? <Sloading color='white' /> : 'Link'}</button>
                    </div>
                    <span className='ms-2'>{msg}</span>
                </div>
            </div>
        </div>

    )
}

export default LinkCustomer