import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

function NotFound404() {

  useEffect(() => {
    const backdrop = document.getElementsByClassName('modal-backdrop')
    backdrop.length > 0 ? backdrop[0].remove() : <></>
  }, [])
  return (
    <section className='d-flex contianer' style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Helmet>
        <title>404</title>
      </Helmet>
      <img src={`${process.env.PUBLIC_URL}/images/error404.png`} alt='Oops! 404 Error. Page not found' className='img-fluid' />
    </section>
  )
}

export default NotFound404