import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeTotalItem, userInfoDefault } from '../../redux/UserInfoSlice'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function NotAuthorized({ path }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const backdrop = document.getElementsByClassName('modal-backdrop')
    backdrop.length > 0 ? backdrop[0].remove() : <></>
    localStorage.removeItem('token')
    dispatch(userInfoDefault())
    dispatch(changeTotalItem('set0'))
  }, [dispatch])

  return (
    <div className='d-flex container' style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: '80vh', gap: '30px' }}>
      <Helmet>
        <title>Not Authroized</title>
      </Helmet>
      <img src={`${process.env.PUBLIC_URL}/images/notauth.png`} alt='Oops! Not Authroized, Login again.' className='img-fluid' style={{ maxWidth: '250px' }} />
      <button className='h6 secondry-button m-1 ps-5 pe-5' style={{ width: 'fit-content' }} onClick={() => navigate(`/user/loginUser/?redirect=${path}`)}>Login</button>
    </div>
  )
}

export default NotAuthorized