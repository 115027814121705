import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dropdown } from '../../redux/UserInfoSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { message, catchError, CrossIcon, errorName, ErrorUp, getToken, Loading, NoConnection, NoData, NotAuthorized, SearchIcon, Sloading, url } from '../../base'
import axios from 'axios'
import { SingleProduct } from '../../components'
import { Helmet } from 'react-helmet'

function SearchAllProduct() {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [error, setError] = useState(null)
    const [pageEnd, setPageEnd] = useState(true)
    const [search, setSearch] = useState('')
    const [searchWords, setSearchWords] = useState('')
    const [products, setProducts] = useState(null)
    const location = useLocation()
    const p = new URLSearchParams(location.search).get('p')
    const dispatch = useDispatch()
    const productError = useSelector((state) => state.product.productError)
    const { user, is_dukandar, userInfoLoading } = useSelector((state) => state.userInfo)

    async function productSearch(page, searchWords) {
        // page === 1 ? setProducts(null) : <></>
        try {
            setLoading(true)
            const token = getToken()
            const request = await axios.get(`${url}/api/product/searchAllProduct/?page=${page}&p=${encodeURIComponent(searchWords)}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log('request', request.data)
            if (request.data && request.data.error_message) {
                request.data.error_message === errorName.invalidPage ? setPageEnd(true) : request.data.error_message === 'No Products' ? setProducts(null) : setError(request.data.error_message)
            }
            else {
                console.log('herere', products, page)
                page === 1 ? setProducts(request.data.data) : page !== 1 && products ? setProducts((prev) => { return [...prev, ...request.data.data] }) : <></>
                // products ? setProducts((prev)=>{return [...prev, ...request.data.data ]}) : setProducts(request.data.data)
            }
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
            setError(catchError(error).error_message)
        }
    }
    console.log('products ', products, 'page ', page,)

    // when load more clicked
    function loadMore() {
        productSearch(page + 1, searchWords)
        setPage(prev => prev + 1)
    }

    // runs when search button is clicked
    function getProducts() {
        if (search.trim() === '' || search.trim().length < 3) {
            setError('Please write minimum 4 words !')
        }
        else {
            setPage(1)
            setSearchWords(search)
            setPageEnd(false)
            productSearch(1, search)
        }
    }

    console.log(error)

    useEffect(() => {
        if(p){
            setSearch(p)
            setPage(1)
            setSearchWords(search)
            setPageEnd(false)
            productSearch(1, p)
        }
    }, [p])

    useEffect(() => {
        dispatch(dropdown(false))
    }, [dispatch, p])

    return (
        <section>
            <Helmet>
                <title>Product Search</title>
            </Helmet>
            {(loading && page === 1) || userInfoLoading ? <Loading /> :
                productError === errorName['404Error'] || productError === errorName.internalError || error === errorName.internalError || error === errorName['404Error'] ? <NoConnection /> :
                    error === errorName.networkError || productError === errorName.networkError ? <NoConnection network='true' /> :
                        !user || productError === errorName.authenticationError || error === errorName.authenticationError ? <NotAuthorized path={window.location.pathname} /> :
                            is_dukandar === true || error === errorName.notGrahak || productError === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> : <>
                                <h4 className='heading h4'>Search Your Products</h4>
                                <div className='text-center'>
                                    <div className='position-relative m-auto' style={{ width: '300px' }}>
                                        <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search any product here.' style={{ width: '300px' }} />
                                        <span onClick={() => setSearch('')} className='crossIcon position-absolute ' style={{ top: 0, right: '0px', cursor: 'pointer' }}><CrossIcon style={{ fill: 'gray' }} /></span>
                                        <span onClick={() => getProducts()} className='searchIcon position-absolute' style={{ top: '3px', right: '30px', cursor: 'pointer' }}><SearchIcon style={{ fill: 'red' }} /></span>
                                    </div>
                                </div>

                                {
                                    products ? <div className='my-container'>
                                        <div className='row'>
                                            {
                                                products && products.map((item, key) => {
                                                    return (
                                                        <SingleProduct item={item} key={key} dukandar={is_dukandar} />
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='text-center'>
                                            {
                                                loading ? <Sloading color='red' /> :
                                                    pageEnd === false ? <button className='secondry-button ps-4 pe-4 pt-1 pb-1' style={{ width: 'fit-content' }} onClick={() => loadMore()}>Load More</button> : <></>
                                            }
                                        </div>
                                    </div> :
                                        <NoData message='Oops! No products found.' />
                                }
                                {error ? <ErrorUp message={error} removeMessage={setError} /> : <></>}
                            </>
            }
        </section>
    )
}

export default SearchAllProduct