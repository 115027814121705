import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { incCategoryPage, getCategory } from '../../redux/ProductSlice'
import { Loading, NotAuthorized, NoData, NoConnection, Sloading, errorName, message } from '../../base/index'
import { SingleCategory } from '../../components/index'
import { dropdown } from '../../redux/UserInfoSlice'
import { Helmet } from 'react-helmet'

function Category() {
    const { productLoading, categoryData, productError, categoryFirstTime, categoryLastPage, categoryPage } = useSelector((state) => state.product)
    const { userInfoLoading, user, is_dukandar, dukan_name } = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()
    const path = window.location.pathname
    const [isMoreLoading, setIsMoreLoading] = useState(false)

    function load() {
        setIsMoreLoading(true)
        dispatch(getCategory(categoryPage + 1))
        dispatch(incCategoryPage())
    }

    useEffect(() => {
        dispatch(dropdown(false))
    }, [dispatch])


    useEffect(() => {
        categoryFirstTime === true ? dispatch(getCategory(1)) : <></>
    }, [categoryFirstTime])


    useEffect(() => {
        productLoading === false ? setIsMoreLoading(false) : <></>
    }, [productLoading])

    return (
        <section className='category'>
            <Helmet>
                <title>Category</title>
            </Helmet>
            {
                (categoryFirstTime === true && productLoading === true) || userInfoLoading ? <Loading /> :
                    productError === errorName['404Error'] || productError === errorName.internalError ? <NoConnection /> :
                        productError === errorName.networkError ? <NoConnection network='true' /> :
                            user === null || productError === errorName.authenticationError ? <NotAuthorized path={path} /> :
                                is_dukandar === true || productError === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                                    categoryData !== null ?
                                        <div className='my-container'>
                                            <div className='h4 text-center mt-2' >{dukan_name}</div>
                                            <div className='heading h4 mb-4 mt-1'>Different Categories</div>
                                            <div className='row'>
                                                {
                                                    categoryData.map((item, key) => {
                                                        return (
                                                            <SingleCategory item={item} key={key} />
                                                        )
                                                    })
                                                }
                                            </div>
                                            <h4 />
                                            <div className='d-flex mt-4' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                {
                                                    isMoreLoading ? <Sloading color={'red'} width={'50px'} height={'fit-content'} /> :
                                                        categoryLastPage === false ?
                                                            <button className='h6 secondry-button ps-2 pe-2' style={{ width: '150px' }} onClick={() => load()}>Load more</button> : <></>
                                                }
                                            </div>
                                        </div> : <NoData message='Oops! No category found in your Dukan.' />
            }
        </section>
    )
}

export default Category