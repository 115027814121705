import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading, NotAuthorized, NoConnection, ErrorUp, catchError, url, Sloading, NoData, message, errorName, getToken, scrollFocus, CrossIcon } from '../../base/index'
import { SingleProduct } from '../../components/index'
import { dropdown } from "../../redux/UserInfoSlice";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";


function SnapNShop() {
   const [list, setList] = useState([])
   const [products, setProducts] = useState(null)
   const [data, setData] = useState(null)
   const [display, setDisplay] = useState('none')
   const [error, setError] = useState(null)
   const [bigError, setBigError] = useState(null)
   const [search, setSearch] = useState('')
   const { is_dukandar, userInfoLoading, user } = useSelector((state) => state.userInfo)
   const [loading, setLoading] = useState(false)
   const path = window.location.pathname
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const refrence = useRef(null)
   const [focus, setFocus] = useState(-1)

   async function productSearch(word) {
      try {
         const token = getToken()
         const request = await axios.get(`${url}/api/product/search/?word=${word}`, {
            headers: {
               'Authorization': `Bearer ${token}`
            }
         })
         request.data && setProducts(request.data.data)
         setLoading(false)
      }
      catch (error) {
         setLoading(false)
         setDisplay('none')
         setProducts([])
      }
   }

   function add(product) {
      scrollFocus(refrence)
      if (list.includes(product)) {
         const oldItem = data.filter((item) => {
            return item.name === product
         })
         const newList = list.filter((item) => {
            return item !== product
         })
         const oldData = data.filter((item) => {
            return item.name !== product
         })
         setData([...oldItem, ...oldData])
         setList([product, ...newList])
      }
      else {
         api(product)
      }
   }

   async function api(item) {
      // setIsLoading(true)
      // setBigError(null)
      // setError(null)
      try {
         const token = getToken()
         const request = await axios.post(`${url}/api/product/SnapNShop/`, { 'product': item }, {
            headers: {
               Authorization: `Bearer ${token}`
            }
         })
         if (request.data && request.data.success_message) {
            setList((prev) => { return [item, ...prev] })
            data === null ? setData([request.data.data]) : setData((prev) => { return [request.data.data, ...prev] })
         }
         else
            request.data.error_message === errorName.internalError ? setBigError(request.data.error_message) : setError(request.data.error_message)
      }
      catch (error) {
         setBigError(catchError(error).error_message)
      }
   }


   function remove(product_name) {
      data !== null ? setData((prev) => {
         return prev.filter((item, key) => item.name !== product_name)
      }) : <></>

      setList((prev) => {
         return prev.filter((item, key) => {
            return item !== product_name
         })
      })
   }

   function changeFocus(e) {
      if (display === 'flex' && products) {
         if (e.key === 'ArrowDown') {
            setFocus((prev) => Math.min(prev + 1, products.length - 1));
            e.preventDefault(); // Prevent default scrolling
         } else if (e.key === 'ArrowUp') {
            setFocus((prev) => Math.max(prev - 1, 0));
            e.preventDefault();
         } else if (e.key === 'Enter' && focus >= 0) {
            add(products[focus]); // Handle selection
         }
      }
   }

   useEffect(() => {
      if (search.trim() !== '' && search.length > 2) {
         setFocus(-1)
         setDisplay('flex')
         setLoading(true)
         const timeout = setTimeout(() => {
            productSearch(search)
         }, [300])

         return () => clearTimeout(timeout)
      }
      else {
         setDisplay('none')
      }
   }, [search])

   useEffect(() => {
      dispatch(dropdown(false))
   }, [dispatch])

   return (
      <section className=" sns" style={{ overflow: 'visible' }}>
         <Helmet>
            <title>SnapNShop</title>
         </Helmet>
         {
            userInfoLoading ? <Loading /> :
               bigError === errorName["404Error"] || bigError === errorName.internalError ? <NoConnection /> :
                  bigError === errorName.networkError ? <NoConnection network='true' /> :
                     user === null || bigError === errorName.authenticationError ? <NotAuthorized path={path} /> :
                        is_dukandar === true || bigError === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> : <div className="my-container pt-1" style={{ overflow: 'visible' }}>
                           <div onClick={() => navigate('/cartItem/')} className='h6 secondry-button ps-3 pe-3 pt-1 pb-1 me-0 me-sm-5' style={{ position: 'absolute', marginTop: '15px', right: '20px', width: 'fit-content', cursor: 'pointer' }}>Bill</div>

                           <div className="h4 heading mb-2">SnapNShop</div>
                           <h6 className="h6 mb-3" style={{ textAlign: 'center', color: 'green' }}>Make your shopping, more Easier and More Faster</h6>

                           <div className="searchproduct" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'relative', overflow: 'visible' }}>
                              <div className="searchlist mt-2 mb-2">
                                 <div className='searchlistinput' >
                                    <input ref={refrence} onKeyDown={(e) => changeFocus(e)} className="snsinput h6" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search proudcts here." />
                                    <span onClick={() => setSearch('')} style={{ position: 'absolute', top: '4px', right: '4px', cursor: 'pointer' }}><CrossIcon style={{ width: '19px', fill: 'gray' }} /></span>
                                    {/* <svg onClick={() => setSearch('')} style={{ position: 'absolute', width: '12px', top: '6px', right: '4px', fill: 'gray', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg> */}
                                 </div>
                                 <div className='searchlistdiv' style={{ display: display }}>
                                    <div style={{ width: '100%' }}>{
                                       loading === true ?
                                          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}><Sloading width={25} height={30} color='red' /> </div> :
                                          products && products.map((item, key) => {
                                             return (
                                                <div className="d-flex list" key={key} onClick={() => add(item)} style={{ cursor: 'pointer', justifyContent: 'space-between', alignItems: 'center', backgroundColor: focus === key ? '#ffff0070' : 'transparent', }}>
                                                   <h1 className="h6" key={key}>{item} </h1>
                                                   <i className="fa-solid fa-circle-plus snsplus h6" style={{ cursor: 'pointer', width: '20px', overflow: 'hidden' }}></i>
                                                </div>
                                             )
                                          })
                                    }
                                    </div>
                                 </div>
                              </div>
                           </div>


                           <div className="genereproducts" style={{ marginBottom: '10px', marginTop: '15px' }}>
                              <div style={{ display: 'flex', overflowX: 'auto', gap: '10px', scrollbarWidth: 'none', padding: '0px 0px 4px 0px' }}>
                                 {
                                    list.map((item, key) => {
                                       return (
                                          <div key={key} className="genere h6" onClick={() => remove(item)}>{item} | X </div>
                                       )
                                    })
                                 }
                              </div>
                              {/* {list.length > 0 ? <button className="secondry-button ps-4 pe-4 h6 ms-1 mt-3" style={{ width: 'fit-content' }} onClick={() => api()}>Get Products</button> : <></>} */}
                           </div>

                           {/* for api data to show */}
                           {
                              data !== null ?
                                 <div className="row"> {
                                    data.map((item, key) => {
                                       return (
                                          <SingleProduct key={key} item={item} setPerror={setBigError} dukandar={is_dukandar} snsPage = {true} />
                                       )
                                    })}
                                 </div> : <> </>
                           }
                           {error !== null ? <ErrorUp removeMessage={setError} message={error} /> : <></>}
                        </div>
         }
      </section>
   )
}

export default SnapNShop;