import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dropdown } from '../../redux/UserInfoSlice'
import { getDukanProfile, udpateDukanProfile, dukandarDefault } from '../../redux/DukandarSlice'
import { userDefault } from '../../redux/UserSlice'
import { NoData, NoConnection, NotAuthorized, Loading, ErrorUp, SuccessUp, url, errorName, message, getMapSrc, scrollFocus, nextOnEnter, openLink } from '../../base/index'
import { Helmet } from 'react-helmet'
import { ResetPassword } from '../../components'


function DukanProfile() {
    const { dukanProfile, dukandarLoading, dukandarError, dukandarErrorType, dukandarSuccess } = useSelector((state) => state.dukandar)
    const { userSuccess, userError, userLoading, userErrorType } = useSelector((state) => state.user)
    const { user, is_dukandar, userInfoLoading } = useSelector((state) => state.userInfo)
    const [tempOImage, setTempOImage] = useState(null)
    const [tempBImage, setTempBImage] = useState(null)
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const dispatch = useDispatch()
    const path = window.location.pathname
    const refrence = {
        phoneRef: useRef(null),
        whatsappRef: useRef(null),
        linkRef: useRef(null),
        aboutRef: useRef(null),
        otherdetailRef: useRef(null),
        availableRef: useRef(null)
    }

    function change(e) {
        const { name, value, files } = e.target
        console.log(data, name, value)
        setData((prev) => {
            return { ...prev, [name]: name === 'banner_image' || name === 'owner_image' ? files.length > 0 ? files[0] : dukanProfile[name] : value }
        })
        name === 'banner_image' ? files.length > 0 ? setTempBImage(URL.createObjectURL((files[0]))) : setTempBImage(null) : name === 'owner_image' ? files.length > 0 ? setTempOImage(URL.createObjectURL((files[0]))) : setTempOImage(null) : <></>
    }

    function update() {
        console.log(data)
        if (data.phone_number.toString().length !== 10) {
            console.log(data.phone_number, data.phone_number.length)
            scrollFocus(refrence.phoneRef)
            setError('Enter 10 digit phone number !')
        }
        else if (data.whatsapp_number.toString().length !== 10) {
            scrollFocus(refrence.whatsappRef)
            setError('Enter 10 digit whatsapp number !')
        }
        else if (data.about.length > 120) {
            scrollFocus(refrence.aboutRef)
            setError('About word limit = 120 words !')
        }
        else if (data.other_details.length > 150) {
            scrollFocus(refrence.otherdetailRef)
            setError('Other details word limit = 150 words !')
        }
        else if (data.available_in.length > 500) {
            scrollFocus(refrence.availableRef)
            setError('Available in field word limit = 500 words !')
        }
        else if (!data.owner_image)
            setError('Please select owner image !')
        else if (!data.owner_image.length > 0 && data.owner_image.size > 4 * 1024 * 1025)
            setError('Owner image size should be less then 4 MB')
        else if (!data.owner_image.length > 0 && !data.owner_image.type.includes('image'))
            setError('Please select only image for owner!')
        else if (!data.banner_image)
            setError('Please select banner image !')
        else if (!data.banner_image.length > 0 && data.banner_image.size > 6 * 1024 * 1025)
            setError('Banner image size should be less then 6 MB')
        else if (!data.banner_image.length > 0 && !data.banner_image.type.includes('image'))
            setError('Please select only image for banner !')
        else {
            if (data.owner_image.name && data.banner_image.name) {
                const formData = new FormData();
                for (const key in data) {
                    formData.append(key, data[key]);
                }
                dispatch(udpateDukanProfile({ 'data': formData }));
            }
            else if (!data.owner_image.name && !data.banner_image.name) {
                const { owner_image, banner_image, ...newData } = data
                dispatch(udpateDukanProfile({ 'data': newData }));
            }
            else if (!data.owner_image.name) {
                const { owner_image, ...newData } = data
                const formData = new FormData();
                for (const key in newData) {
                    formData.append(key, data[key]);
                }
                dispatch(udpateDukanProfile({ 'data': formData }));
            }
            else if (!data.banner_image.name) {
                const { banner_image, ...newData } = data
                const formData = new FormData();
                for (const key in newData) {
                    formData.append(key, data[key]);
                }
                dispatch(udpateDukanProfile({ 'data': formData }));
            }
        }

    }


    useEffect(() => {
        dispatch(dropdown(false))
    }, [dispatch])

    useEffect(() => {
        if (dukanProfile === null) {
            dispatch(getDukanProfile())
        }
        else {
            setData(dukanProfile)
        }
    }, [dukanProfile])

    useEffect(() => {
        if (dukandarError !== null && dukandarErrorType === 'normal') {
            setError(dukandarError)
            dispatch(dukandarDefault())
        }
        else if (dukandarSuccess && dukandarSuccess.includes('updated')) {
            setSuccess('Profile updated!')
            dispatch(dukandarDefault())
        }
    }, [dukandarError, dukandarSuccess])

    useEffect(() => {
        if (userError !== null && userErrorType === 'normal') {
            setError(userError)
            dispatch(userDefault())
        }
        else if (userSuccess && userSuccess.includes('Password')) {
            setSuccess('Password changed !')
            dispatch(userDefault())
        }
    }, [userError, userSuccess])


    return (
        <section>
            <Helmet>
                <title>Dukandar Profile</title>
            </Helmet>
            <div style={{ margin: 'auto', maxWidth: '1100px', width: '95%' }}>
                {
                    userLoading || dukandarLoading || userInfoLoading ? <Loading /> :
                        userError === errorName['404Error'] || dukandarError === errorName['404Error'] || userError === errorName.internalError || dukandarError === errorName.internalError ? <NoConnection /> :
                            userError === errorName.networkError || dukandarError === errorName.networkError ? <NoConnection network='true' /> :
                                user === null || userError === errorName.authenticationError || dukandarError === errorName.authenticationError ? <NotAuthorized path={path} /> :
                                    is_dukandar === false || userError === errorName.notDukandar || dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                                        data !== null ?
                                            <>
                                                <div className='dukandetail mt-3' style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                                    <div className="heading h4 mt-0 mb-4">Your Dukan</div>
                                                    <img src={tempBImage ? tempBImage : data.banner_image} alt='banner' className='img-fluid bannerimage' style={{ margin: 'auto' }} />
                                                    <div className='ownerimage m-auto mt-4'>
                                                        <img src={tempOImage ? tempOImage : data.owner_image} className='img-fluid' style={{ height: '100%', width: '100%' }} alt='Malik' />
                                                    </div>
                                                    <h5 className='h5 heading mb-1'>{data.owner}</h5>
                                                    <p className=' h6 m-auto' style={{ width: 'fit-content', textAlign: 'center' }}>{data.about}</p>
                                                    <div className='dukandetail row g-3 mt-3 mb-2'>
                                                        <div className='col-sm-5 col-md-6 '>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Dukan Id : </span>MKD-{data.id}</h1>
                                                        </div>
                                                        <div className='col-sm-7 col-md-6'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Owner : </span>{data.owner}</h1>
                                                        </div>
                                                        <div className='col-sm-5 col-md-6'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Dukan : </span>{data.name}</h1>
                                                        </div>
                                                        <div className='col-sm-7 col-md-6'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Address : </span>{data.address}, {data.area}</h1>
                                                        </div>
                                                        <div className='col-sm-5 col-md-6'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Phone no. : </span>{data.phone_number}</h1>
                                                        </div>
                                                        <div className='col-sm-7 col-md-6'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Whatsapp no. : </span>{data.whatsapp_number}</h1>
                                                        </div>
                                                        <div className='col-6 col-sm-5 col-md-6 col-lg-3'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Total Products : </span>{data.total_product}</h1>
                                                        </div>
                                                        <div className='col-6 col-sm-7 col-md-6 col-lg-3'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Total Category : </span>{data.total_category}</h1>
                                                        </div>
                                                        <div className='col-6 col-sm-5 col-md-6 col-lg-3'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Total Orders : </span>{data.total_order}</h1>
                                                        </div>
                                                        <div className='col-6 col-sm-7 col-md-6 col-lg-3'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Total Users : </span>{data.total_user}</h1>
                                                        </div>
                                                        <div className='12'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Delivery Available in : </span>{data.available_in}</h1>
                                                        </div>
                                                        <div className='col-12'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Other Details : </span></h1>
                                                            {
                                                                data.other_details.split('/n').map((item, key) => {
                                                                    return <p key={key} className='h6'>{key + 1}. {item}</p>
                                                                })
                                                            }
                                                        </div>

                                                        {/* for map */}
                                                        <>
                                                            {
                                                                getMapSrc(dukanProfile.map_location) ?
                                                                    <div className='col-12 dukanmap' style={{ textAlign: 'center' }}>
                                                                        <h1 className='h5 mt-2 mb-3'><span style={{ color: 'red' }}>Location </span></h1>
                                                                        <iframe title="mahajan kirana map" src={getMapSrc(dukanProfile.map_location)} style={{ border: "0", height: 'inherit', width: '100%' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                                    </div> : <></>
                                                            }
                                                        </>

                                                    </div>

                                                    {/* whatsapp group icon */}
                                                    <div style={{ right: '3px', position: 'absolute', bottom: '9%', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1px', cursor: 'pointer' }} onClick={() => openLink(dukanProfile.whatsapp_link)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ lineHeight: 'normal', padding: '4px 7px', borderRadius: '35px', width: '40px', backgroundColor: '#00800029', fill: 'green' }}><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
                                                        <h6 style={{ fontSize: '12px', color: 'green' }}>Join Group</h6>
                                                    </div>
                                                </div>

                                                <hr />

                                                <h5 className='h4 heading'>Update Dukan Profile</h5>
                                                <div className="row g-3">
                                                    <div className='col-6 col-sm-6 col-md-2 col-lg-3'>
                                                        <label className='h6'>Phone No. : </label>
                                                        <input ref={refrence.phoneRef} onKeyDown={(e) => nextOnEnter(e, refrence.whatsappRef)} className='h6' type='number' name='phone_number' value={data.phone_number} onChange={(e) => change(e)} placeholder='1234567890' />
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-2 col-lg-3'>
                                                        <label className='h6'>Whatsapp No. : </label>
                                                        <input ref={refrence.whatsappRef} onKeyDown={(e) => nextOnEnter(e, refrence.linkRef)} className='h6' type='number' name='whatsapp_number' value={data.whatsapp_number} onChange={(e) => change(e)} placeholder='1234567890' />
                                                    </div>
                                                    <div className='col-12 col-sm-12 col-md-8 col-lg-6'>
                                                        <label className='h6'>Whatsapp Group : </label>
                                                        <input ref={refrence.linkRef} onKeyDown={(e) => nextOnEnter(e, refrence.aboutRef)} className='h6' type='url' name='whatsapp_link' value={data.whatsapp_link} onChange={(e) => change(e)} placeholder='Dukan Whatsapp group link' />
                                                    </div>
                                                    <div className='col-12 col-md-9 order-1'>
                                                        <label className='h6'>About : <span style={{ color: 'red', fontSize: '12px' }}>(words = {data.about.length})</span> </label>
                                                        <textarea ref={refrence.aboutRef} onKeyDown={(e) => nextOnEnter(e, refrence.otherdetailRef)} rows={2} className='h6' type='text' name='about' value={data.about} onChange={(e) => change(e)} placeholder='About your dukan' />
                                                    </div>
                                                    <div className='col-sm-6 col-md-3 order-4 order-md-2 mb-3 mb-sm-0'>
                                                        <label className='h6'>Owner Image :</label>
                                                        <input className='h6' type='file' style={{ borderBottom: 'none' }} name='owner_image' onChange={(e) => change(e)} />
                                                        <h6 style={{ fontSize: '0.74rem', color: 'red', position: 'absolute' }}>Image size : 4 MB or less</h6>
                                                    </div>
                                                    <div className='col-12 order-3 order-md-5'>
                                                        <label className='h6'>Other Details : <span style={{ color: 'red', fontSize: '12px' }}>(words = {data.other_details.length})</span></label>
                                                        <textarea ref={refrence.otherdetailRef} onKeyDown={(e) => nextOnEnter(e, refrence.availableRef)} rows={5} className='h6' type='text' name='other_details' value={data.other_details} onChange={(e) => change(e)} placeholder='Dukan k discount, delivery, khasiyat ki jankari /n mtlb new line' />
                                                    </div>
                                                    <div className='col-sm-6 col-md-3 order-5 order-md-4'>
                                                        <label className='h6'>Banner Image </label>
                                                        <input className='h6' type='file' style={{ borderBottom: 'none' }} name='banner_image' onChange={(e) => change(e)} />
                                                        <h6 style={{ fontSize: '0.74rem', color: 'red', position: 'absolute' }}>Image size : 6 MB or less</h6>
                                                    </div>
                                                    <div className='col-12 col-md-9 order-2'>
                                                        <label className='h6'>Available in : <span style={{ color: 'red', fontSize: '12px' }}>(words = {data.available_in.length})</span> </label>
                                                        <textarea ref={refrence.availableRef} onKeyDown={(e) => nextOnEnter(e, refrence.phoneRef)} rows={3} className='h6' type='text' name='available_in' value={data.available_in} onChange={(e) => change(e)} placeholder='45124 (Maheshwar), 450001 (Khandwa)' />
                                                    </div>

                                                </div>
                                                <button className='secondry-button h6 ps-3 pe-3 mt-5 mt-md-3 mb-1' style={{ width: 'fit-content' }} onClick={() => update()}>Update profile</button>

                                                <hr />
                                                <ResetPassword dispatch={dispatch} />

                                            </> : <NoData message='Oops! You do not have Dukan. ' />
                }
                {
                    error !== null ? (<ErrorUp removeMessage={setError} message={error} />) : success !== null ? (<SuccessUp removeMessage={setSuccess} message={success} />) : <></>

                }
            </div>
        </section>
    )
}

export default DukanProfile



// Although you are storing the phone_number and whatsapp_number as BigIntegerField (an integer) in your Django model, many APIs (including Django REST Framework) will serialize these large numbers as strings to avoid issues with JavaScript.
// Why? JavaScript (commonly used on the frontend) has limitations with how large numbers (such as phone numbers) are handled. JavaScript’s Number type may cause rounding issues for very large numbers, especially for things like phone numbers. To avoid this, many systems serialize large numbers as strings.
// Pin (Postal Code):

// Postal codes are usually stored as integers in Django (as an IntegerField), and because they are typically within the range of numbers that JavaScript can handle without precision loss, they are sent as integers to the frontend.