const errorName = {
    'invalidPage': 'Invalid page !',
    'authenticationError': 'Given token not valid for any token type',
    'tooManyRequest' : 'Oops! Too many request',
    'networkError': 'Network Error !',
    '404Error' : '404 Error !',
    'internalError': 'Internal Error !',
    'noData' : 'No data found !',
    'notDukandar' : 'You are not dukandar !',
    'notGrahak' : 'You are not grahak !'
}

const pattern = {
    'usernamePattern' : /^[a-zA-Z0-9_@$]*$/ ,
    'emailPattern' : /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ ,
    'categoryProductPattern' : /^[a-zA-Z0-9\|\(\)\*\& ]+$/
}

const fallbackImg = `${process.env.PUBLIC_URL}/fallbackimg.jpg`

// const url = 'http://127.0.0.1:8000'
const url = 'https://mahajankirana.in'

const message = {
    'notDukandar' : 'Oops! You do not have Dukandar Account.',
    'notGrahak' : 'Oops! You do not have Grahak Account.'
}

export default errorName
export { pattern, url, message, fallbackImg }