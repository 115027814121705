import React from 'react'


function Loading() {
    return (
        <div className='container' style={{width : '100vw!important', height : '70vh', display : 'flex', justifyContent : 'center', alignItems : 'center', flexDirection : 'column'}}>
            <h5 className='loadingmk'><span style={{color : 'orange'}}>M</span><span style={{color : 'green'}}>K</span></h5>
            <h5 className='loadingname'><span style={{color : 'orange'}}>Mahajan</span><span style={{color : 'green'}}>Kirana</span></h5>
            <div className='d-flex loadingflex'>
                <h5>Apne nagar ki kirana</h5>
                <span style={{overflow : 'visible'}}>
                <svg xmlns="http://www.w3.org/2000/svg" className='fa-beat' viewBox="0 0 640 512"><path d="M112 0C85.5 0 64 21.5 64 48l0 48L16 96c-8.8 0-16 7.2-16 16s7.2 16 16 16l48 0 208 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L64 160l-16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l16 0 176 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L64 224l-48 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l48 0 144 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L64 288l0 128c0 53 43 96 96 96s96-43 96-96l128 0c0 53 43 96 96 96s96-43 96-96l32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64 0-32 0-18.7c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7L416 96l0-48c0-26.5-21.5-48-48-48L112 0zM544 237.3l0 18.7-128 0 0-96 50.7 0L544 237.3zM160 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm272 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"/></svg>
                </span>
            </div>
        </div>
    )
}

export default Loading;