import React, { useEffect, useState, useRef } from 'react'
import { getToken, NoData, Sloading, url, nextOnEnter, scrollFocus, ErrorUp, SuccessUp, catchError, BilldeskIcon } from '../base'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { getSearchedCustomer, dukandarDefault, updateDukanCustomer, setDukandarError } from '../redux/DukandarSlice'
import { useNavigate } from 'react-router-dom'

function UpdateDukanCustomer({ setOperationLoading }) {
  const [data, setData] = useState(null)
  const { dukandarError, dukandarSuccess, searchedCustomer } = useSelector((state) => state.dukandar)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [updating, setUpdating] = useState(false)
  const [using, setUsing] = useState('number')
  const [query, setQuery] = useState('')
  const [customer, setCustomer] = useState(null)
  const [loading, setLoading] = useState(false)
  const [display, setDisplay] = useState('none')
  const dispatch = useDispatch()
  const refrence = {
    userRef: useRef(null),
    usernameRef: useRef(null),
    nameRef: useRef(null),
    numberRef: useRef(null),
    houseRef: useRef(null),
    streetRef: useRef(null),
    colonyRef: useRef(null),
    cityRef: useRef(null),
  }
  const navigate = useNavigate()


  function change(e) {
    const { name, value } = e.target
    setData((prev) => {
      return {
        ...prev,
        [name]: name === 'checkbox' ? !prev.checkbox : name === 'user' && value === '' ? null : value
      }
    })
  }


  async function search() {
    setLoading(true)
    setDisplay('flex')
    try {
      const token = getToken()
      const request = await axios.get(`${url}/api/dukandar/searchCustomer/?using=${using}&query=${query}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (request.data && request.data.data) {
        setLoading(false)
        setCustomer(request.data.data)
        setDisplay('flex')
      }
      else if (request.data && request.data.error_message) {
        setDisplay('none')
      }
    }
    catch (error) {
      setDisplay('none')
    }
  }

  function changeQuery(using) {
    setQuery('')
    setUsing(using)
  }

  function update() {
    console.log(data)
    const trimed_name = data.name.trim()
    if (trimed_name === '') {
      setError('Enter customer name !')
      scrollFocus(refrence.nameRef)
    }
    else if (data.number.toString().length != 10) {
      setError('Enter 10 digit number !')
      scrollFocus(refrence.numberRef)
    }
    else if (data.house_no < 0 || data.house_no === '') {
      setError('Enter house number !')
      scrollFocus(refrence.houseRef)
    }
    else if (data.street_no < 0 || data.street_no === '') {
      setError('Enter street number !')
      scrollFocus(refrence.streetRef)
    }
    else {
      setOperationLoading(true)
      const { user, username, ...newdata } = data
      dispatch(updateDukanCustomer(newdata))
    }
  }

  console.log(searchedCustomer)
  async function linkUser() {
    if (!data.checkbox) {
      setError('Select check box !')
    }
    else if (data.user === null || data.user <= 0) {
      setError('Enter user id to link !')
      scrollFocus(refrence.userRef)
    }
    else if (data.username.trim() === '') {
      setError('Enter username to link !')
      scrollFocus(refrence.usernameRef)
    }
    else {
      setUpdating(true)
      try {
        const token = getToken()
        const request = await axios.put(`${url}/api/dukandar/linkUserWithCustomer/`, { 'customer_id': searchedCustomer.id, 'user_id': data.user, 'username': data.username }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        console.log(request.data)
        if (request.data && request.data.error_message) {
          setError(request.data.error_message)
        }
        else {
          setSuccess(request.data.success_message)
        }
        setData((prev) => { return { ...prev, 'checkbox': false } })
        setUpdating(false)
      }
      catch (error) {
        setUpdating(false)
        console.log(error)
        setData((prev) => { return { ...prev, 'checkbox': false } })
        dispatch(setDukandarError(catchError(error).error_message))
      }
    }
  }

  async function dislinkUser() {
    if (!data.checkbox) {
      setError('Select check box !')
    }
    else {
      try {
        setUpdating(true)
        const token = getToken()
        const request = await axios.put(`${url}/api/dukandar/disLinkUserWithCustomer/`, { 'customer_id': searchedCustomer.id }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if (request.data && request.data.error_message) {
          setError(request.data.error_message)
        }
        else {
          setSuccess(request.data.success_message)
          setData((prev) => { return { ...prev, 'user': null, 'username': '' } })
        }
        setData((prev) => { return { ...prev, 'checkbox': false } })
        setUpdating(false)
      }
      catch (error) {
        setUpdating(false)
        setData((prev) => { return { ...prev, 'checkbox': false } })
        dispatch(setDukandarError(catchError(error).error_message))
      }
    }
  }


  useEffect(() => {
    const q = query.toString()
    if (using === 'number' && q.length === 10) {
      search()
    }
    else if (using === 'name' && q.length > 3) {
      search()
    }
    else {
      setDisplay('none')
    }
  }, [query])

  useEffect(() => {
    if (searchedCustomer) {
      setData({ ...searchedCustomer, 'checkbox': false })
      setDisplay('none')
    }
  }, [searchedCustomer])

  useEffect(() => {
    if (dukandarError) {
      setError(dukandarError)
      dispatch(dukandarDefault())
    }
    else if (dukandarSuccess === 'Customer updated !') {
      setSuccess('Customer updated successfully !')
      dispatch(dukandarDefault())
    }
  }, [dukandarError, dukandarSuccess])

  console.log(data)
  return (
    <div className='container' style={{ minHeight: '350px', position: 'relative' }}>

      <div className='searchlist mt-1 mb-4'>
        <div className='searchlistinput'>
          <input className="h6" type={using === 'number' ? 'number' : 'text'} onChange={(e) => setQuery(e.target.value)} value={query} placeholder="Search customer here." />
          <svg className={using === 'number' ? 'd-block' : 'd-none'} onClick={() => changeQuery('name')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
          <svg className={using === 'name' ? 'd-block' : 'd-none'} onClick={() => changeQuery('number')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256l0 32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32l0 80 0 32c0 17.7 14.3 32 32 32s32-14.3 32-32l0-32c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" /></svg>
        </div>
        <div className="searchlistdiv" style={{ display: display }}>
          <div style={{ width: '100%' }}>{
            loading === true ?
              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}><Sloading width={25} height={30} color='red' /> </div> :
              customer && customer.map((item, key) => {
                return (
                  <div className="searchlistvalue" key={key} onClick={() => dispatch(getSearchedCustomer(item.id))}>
                    <h1 className="h6" key={key}>{item.name}</h1>
                    <hr style={{ margin: '0px' }} />
                  </div>
                )
              })
          }
          </div>
        </div>
      </div>

      {
        data ?
          <>
            <span onClick={()=>navigate(`/dukandar/customer/${data.id}/detailNorders/`)}>
              <BilldeskIcon style={{ position: 'absolute', right: '10px', top: '40px', fill: 'blue', 'cursor': 'pointer', width: '18px' }} />
            </span>
            <div className='row'>
              <div className='mt-2 col-md-6 col-lg-6'>
                <label>Name : </label>
                <input ref={refrence.nameRef} onKeyDown={(e) => nextOnEnter(e, refrence.numberRef)} type='text' name='name' value={data.name} onChange={(e) => change(e)} placeholder='Udit Mahajan' />
              </div>
              <div className='mt-2 col-md-6 col-lg-3'>
                <label>Number : </label>
                <input ref={refrence.numberRef} onKeyDown={(e) => nextOnEnter(e, refrence.houseRef)} type='number' name='number' value={data.number} onChange={(e) => change(e)} placeholder='1234567890' />
              </div>
              <div className='mt-2 col-md-6 col-lg-3'>
                <label>House no. : </label>
                <input ref={refrence.houseRef} onKeyDown={(e) => nextOnEnter(e, refrence.streetRef)} type='number' name='house_no' value={data.house_no} onChange={(e) => change(e)} placeholder='31' />
              </div>
              <div className='mt-2 col-md-6 col-lg-2'>
                <label>Stree / Ward no. : </label>
                <input ref={refrence.streetRef} onKeyDown={(e) => nextOnEnter(e, refrence.colonyRef)} type='number' name='street_no' value={data.street_no} onChange={(e) => change(e)} placeholder='5' />
              </div>
              <div className='mt-2 col-md-6 col-lg-7'>
                <label>Address : </label>
                <input ref={refrence.colonyRef} onKeyDown={(e) => nextOnEnter(e, refrence.cityRef)} type='text' name='colony' value={data.colony} onChange={(e) => change(e)} placeholder='Dhan Mandi Tilak Marg' />
              </div>
              <div className='mt-2 col-md-6 col-lg-3'>
                <label>City : </label>
                <input ref={refrence.cityRef} onKeyDown={(e) => nextOnEnter(e, refrence.nameRef)} type='text' name='city' value={data.city} onChange={(e) => change(e)} placeholder='Maheshwar' />
              </div>
            </div>
            <button className='mt-3 primary-button h6 ps-4 pe-4 pt-1 pb-1' style={{ width: 'fit-content' }} onClick={(e) => update()}>Update Customer</button>
            <br />

            {/* <label className='h6 mt-4'>Have user id ? Link this customer with a User</label>
            <input type='checkbox' checked={data.checkbox} name='checkbox' onChange={(e) => change(e)} style={{ marginLeft: '10px', width: 'fit-content' }} /> */}

            {
              updating ? <div className='mt-5 updatecustomer'> <Sloading color='red' /> </div> :
                <div className=''>
                  <div className='heading h5'>Link the customer with User</div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label>MK User Id : </label>
                      <input ref={refrence.userRef} onKeyDown={(e) => nextOnEnter(e, refrence.usernameRef)} type='number' name='user' value={data.user} onChange={(e) => change(e)} placeholder='5' />
                    </div>
                    <div className='col-md-6'>
                      <label>MK Username : </label>
                      <input ref={refrence.usernameRef} onKeyDown={(e) => nextOnEnter(e, refrence.userRef)} type='text' name='username' value={data.username} onChange={(e) => change(e)} placeholder='kamaljimahajan' />
                    </div>
                  </div>

                  <label className='h6 mt-4'>Really want to perform operation ? </label>
                  <input type='checkbox' checked={data.checkbox} name='checkbox' onChange={(e) => change(e)} style={{ marginLeft: '10px', width: 'fit-content' }} />

                  <div className='mt-3'>
                    <button className='h6 secondry-button ps-4 pe-4 pt-1 pb-1 me-3' style={{ width: 'fit-content' }} onClick={() => linkUser()}>Link</button>
                    <button className='h6 secondry-button ps-4 pe-4 pt-1 pb-1' style={{ width: 'fit-content' }} onClick={() => dislinkUser()}>DisLink</button>
                  </div>
                </div>
            }

            <br />
          </>
          : <NoData message={`Oops! No customer found this ${using}`} />
      }
      {
        error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
      }
    </div>
  )
}

export default UpdateDukanCustomer