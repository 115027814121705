import React from 'react'
import { Stars } from './index'

const ProductReviewList = React.memo(({item}) =>{

    return (
        <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <h6 className='h6' style={{color : 'red'}}>{item.username}</h6>
                <h6 className='h6 review'>{item.rating}<Stars rating={item.rating} /></h6>
            </div>
            <h3 className='h5'>{item.comment}</h3>
        </div >
    )
}) 

export default ProductReviewList;