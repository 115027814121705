import React, { useEffect, useState } from 'react'
import { scrollFocus } from '../base'

function BillDeskItem({reffrence, index, item, quantity, setQuantity, setTotal, setSaving, setTotalQuantity, removeProduct }) {
  const [oldTotal, setOldTotal] = useState(0)
  const [done, setDone] = useState(false)
  
  function change(e) {
    setSaving((prev) => {return prev + ((item.mrp - item.price) * e.target.value) - ((item.mrp - item.price) * quantity)})
    setTotal((prev) => {return prev + (Number(e.target.value) * item.price) - oldTotal})
    setTotalQuantity((prev) => {return (prev + (Number(e.target.value) - quantity))})
    setOldTotal(Number(e.target.value) * item.price)
    setQuantity(`uniqueId_${item.uniqueId}`, e.target.value)
  }
  
  function remove(id) {
       setSaving((prev)=>{return prev -(quantity * (item.mrp - item.price))})
       setTotalQuantity((prev)=>{return prev - quantity})
       setTotal((prev)=>{return prev - (quantity * item.price)})
       removeProduct(id)
  }


  function handleKeyDown(e, id) {
    if(e.key === 'Enter')
      {
        scrollFocus(reffrence)
        document.getElementsByClassName('tr')[0].style.backgroundColor = 'white'

      }
    e.key === 'Delete' ? remove(id) : <></>
  }

  useEffect(() => {
    document.getElementsByClassName('input')[0].focus()
    document.getElementsByClassName('tr')[0].style.backgroundColor = 'rgba(0, 128, 0, 0.187)'
    setOldTotal(quantity * item.price)

    // const time = setTimeout(()=>{
    //   document.getElementsByClassName('tr')[0].style.backgroundColor = 'transparent'
    // },[3000])
    // return ()=> clearTimeout(time)
  }, [item])
  
  
  return (
    <tr className='tr'>   
          <td className='' style={done ? {fontSize : '0.99rem', color : 'green', cursor : 'pointer', fontWeight : 'bolder'} : {fontSize : '0.96rem', color : 'black', cursor : 'pointer'} } onClick={()=>setDone(!done)}>{index}</td>
          <td className='h6' onClick={()=>setDone(!done)} style={{cursor : 'pointer'}}>{item.name }</td>
          <td className='h6 '>{item.offer }</td>
          <td className='h6 '>{item.mrp }</td>
          <td className='h6 '>{item.price}</td>
          <td className='h6 '> <input onKeyDown={(e)=>handleKeyDown(e, index-1)} className='input' style={{ textAlign: 'center' }} type='number' value={quantity} onChange={(e) => change(e)} /></td>
          <td className='h6 '>{(item.mrp - item.price) * quantity}</td>
          <td className='h6 '>{quantity * item.price}</td>
          <td className='h6 '><i className="h6 fa-regular fa-trash-can" style={{ cursor: 'pointer' }} onClick={()=>remove(index-1)}></i></td>
       
    </tr>
  )
};

export default BillDeskItem;