import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeTotalItem, reviewsUpdate, userInfoDefault } from "../redux/UserInfoSlice";
import { ErrorUp, Sloading, SuccessUp, catchError, errorName, getToken, url } from '../base'
import Stars from "./Stars";
import Carousel from 'react-bootstrap/Carousel';

function AppReviews() {
    const { user, app_reviews, is_reviewd, total_reviews, avg_rating, userInfoLoading } = useSelector((state) => state.userInfo)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSucces] = useState(null)
    const dispatch = useDispatch()
    const [num, setNum] = useState(1)
    const [review, setReview] = useState({
        'username': user,
        'rating': 5,
        'comment': ''
    })

    async function getReviews() {
        setError(null)
        setSucces(null)
        setIsLoading(true)
        try {
            const request = await axios.get(`${url}/api/app/reviews/`)
            request.data && request.data.error_message ?
                dispatch(reviewsUpdate({ 'data': null, 'type': 'get' })) :
                dispatch(reviewsUpdate({ 'data': request.data.data, 'type': 'get' }))
            setIsLoading(false)
        }
        catch (error) {
            setIsLoading(false)
        }
    }

    async function getIsReviewd() {
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/app/isReviewd/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            request.data ? dispatch(reviewsUpdate({ 'is_reviewd': request.data.is_reviewd, 'type': 'onlyreviewd' })) : <></>
        }
        catch (error) {
            dispatch(reviewsUpdate({ 'is_reviewd': true, 'type': 'onlyreviewd' }))
        }
    }

    async function createReview() {
        setError(null)
        setSucces(null)
        if (review.rating < 1 || review.rating > 5) {
            setError('Enter 1 to 5 rating!')
        }
        else if (review.comment.trim() === '') {
            setError('Please write comment!')
        }
        else if (review.comment.trim().length > 115) {
            setError('Maximum 115 words review!')
        }
        else {
            try {
                const token = getToken()
                const request = await axios.post(`${url}/api/app/reviews/`, review, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                if (request.data && request.data.error_message) {
                    request.data.error_message === errorName.internalError ? setError('Oops! can not save review, Try again.') :
                        setError(request.data.error_message)
                    request.data.error_message === 'User already reviewed!' ?
                        dispatch(reviewsUpdate({ 'is_reviewd': true, 'type': 'onlyreviewd' })) :
                        <></>
                }
                else {
                    dispatch(reviewsUpdate({ 'data': review, 'rating': review.rating, 'type': 'update' }))
                    setSucces('Thanks for the review!')
                }
            }
            catch (error) {
                const d = catchError(error).error_message
                if (d === errorName.authenticationError) {
                    dispatch(userInfoDefault())
                    dispatch(changeTotalItem('set0'))
                    setError('Oops! Session expire, Please login again.')
                }
                else if (d === errorName.networkError || d === errorName.internalError || d === errorName["404Error"])
                    setError('Oops! Can not save review, please try again.')
                else
                    setError(catchError(error))
            }
            setReview({
                'username': user,
                'rating': 5,
                'comment': ''
            })
        }
    }

    function change(e) {
        setReview((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    useEffect(() => {
        if (app_reviews === null) {
            getReviews()
            getIsReviewd()
        }
    }, [app_reviews])

    useEffect(() => {
        user ? setReview(({
            'username': user,
            'rating': 5,
            'comment': ''
        })) : <></>
    }, [user])

    return (
        <>
            <div className='text-center mb-4 d-flex' style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <h4 className="h5 d-flex mb-1" style={{ alignItems: 'center', gap: '10px' }}>Total Reviews : {userInfoLoading ? <Sloading color={'black'} width={'20px'} height={'fit-content'} /> : total_reviews}</h4>
                {userInfoLoading ? <h4 className="d-flex h5" style={{ alignItems: 'center', gap: '10px' }}>Average Rating : <Sloading color={'black'} width={'20px'} height={'fit-content'} /></h4> : <h4 className="d-flex h5" style={{ alignItems: 'center', gap: '10px' }}>Average Rating : {avg_rating} <Stars rating={avg_rating} /></h4>}
            </div>

            <div className='row'>

                <div className='col-md-6 col-lg-6 left order-2 order-md-1' style={{ position: 'relative' }}>
                    <div className={num === 1 ? 'd-flex last-div' : "d-none last-div"} style={user && is_reviewd === false ? { alignItems: 'start' } : { alignItems: 'center' }}>
                        {
                            user && is_reviewd === false ?
                                <>
                                    <div className="d-flex" style={{ alignItems: 'center' }}>
                                        <label className="h5 ">Rating : </label>
                                        <select className='form-select h6' style={{ width: 'fit-content' }} name='rating' value={review.rating} onChange={(e) => change(e)} >
                                            <option value={5}>5</option>
                                            <option value={4}>4</option>
                                            <option value={3}>3</option>
                                            <option value={2}>2</option>
                                            <option value={1}>1</option>
                                        </select>
                                    </div>
                                    <label className="h5 mt-3 mb-2">Comment : </label>
                                    <textarea rows={3} className="h6" type='text' name='comment' onChange={(e) => change(e)} value={review.comment} placeholder="Maximum 115 words." />
                                    <h6 style={{ fontSize: '13px', color: 'red', position: 'absolute', bottom: '90px' }}>words : {review.comment.length}</h6>
                                    <button className="secondry-button ps-5 pe-5 pt-1 pb-1 mb-0" onClick={() => createReview()} disabled={success ? 'true' : false} style={{ width: 'fit-content' }}>submit</button>
                                </> :
                                user && is_reviewd === true ? <h3 className="h5">You already reviewd!</h3> :
                                    <>
                                        <h5 className="h5 mb-4">Please login to Review</h5>
                                        <Link to={'/user/loginUser/'} className="primary-button h6 m-0" style={{ padding: '5px 40px', width: 'fit-content' }}>Login</Link>
                                    </>
                        }
                    </div>

                    <div className={num === 2 ? 'd-flex last-div' : "d-none last-div"} style={{overflow : 'visible', alignItems: 'start' }} >
                        <div className="heading h4 mb-3">Contact Us</div>
                        <div className="d-flex mb-3 " style={{overflow : 'visible', alignItems: 'center', justifyContent: 'center', gap: '23px' }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg><h5 className="h5">Bhag Singh Marg, Maheshwar</h5></div>
                        <div className="d-flex mb-3" style={{overflow : 'visible', alignItems: 'center', justifyContent: 'center', gap: '20px' }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg><h5 className="h5">mahajankiranamhs@gmail.com</h5></div>
                        <div className="d-flex mb-3" style={{overflow : 'visible', alignItems: 'center', justifyContent: 'center', gap: '20px' }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg><h5 className="h5">+91 6260829322, +91 9685607577</h5></div>
                        <div className="d-flex" style={{overflow : 'visible', alignItems: 'center', justifyContent: 'center', gap: '20px' }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg><h5 className="h5">+91 6260829322, +91 8319748630</h5></div>
                    </div>
                    <div className={num === 3 ? 'd-flex last-div' : "d-none last-div"}>
                        <iframe title="mahajan kirana map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1847.3702671624258!2d75.58534560928277!3d22.17394657731922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39626547c57dafaf%3A0x19e2b786d2177135!2sMahajan%20Kirana!5e0!3m2!1sen!2sin!4v1712076414597!5m2!1sen!2sin" style={{ border: "0", height: 'inherit', width: '100%' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div className="row mt-4" style={{ justifyContent: 'space-evenly', alignItems: 'center', border: '1px solid #00000052', width: '100%' }}>
                        <h5 className={num === 1 ? "current-btn h5 col-4 text-center pt-1 pb-1" : 'last-btn h5 col-4 text-center pt-1 pb-1'} onClick={() => setNum(1)} style={{ borderRight: '1px solid #00000052' }}>Review</h5>
                        <h5 className={num === 2 ? "current-btn h5 col-4 text-center pt-1 pb-1" : 'last-btn h5 col-4 text-center pt-1 pb-1'} onClick={() => setNum(2)} style={{ borderRight: '1px solid #00000052' }}>Contact</h5>
                        <h5 className={num === 3 ? "current-btn h5 col-4 text-center  pt-1 pb-1" : 'last-btn h5 col-4 text-center pt-1 pb-1'} onClick={() => setNum(3)}>Location</h5>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 order-1 order-md-2 text-center'>
                    {
                        isLoading ? <div className="d-flex" style={{ justifyContent: 'center' }}> <Sloading color={'red'} width={'50px'} height={'fit-content'} /> </div> :
                            app_reviews !== null ?
                                <Carousel>
                                    {app_reviews !== null && app_reviews.map((item, key) => {
                                        return (
                                            <Carousel.Item key={key}>
                                                <div className="singleappreview" key={key}>
                                                    <h1 className="h5" style={{ color: 'red' }}>{item.username}</h1>
                                                    <h1 className="h5 d-flex">{item.rating} <Stars rating={item.rating} /></h1>
                                                    <h1 className="h6">{item.comment}</h1>
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })}
                                </Carousel> : <h1 className="h5 text-center">No reviews yet! Be the 1st to review.</h1>
                    }
                </div>
            </div>
            {
                error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSucces} message={success} /> : <></>
            }
        </>
    )
}

export default AppReviews;

