import React, {useEffect, useState} from 'react'

function SuccessUp({message, removeMessage }) {
  const [display, setDisplay] = useState('flex')
  const [success, setSuccess] = useState(null)

  useEffect(() =>{
    setSuccess(message) 
    const timeout = setTimeout(()=>{
        setDisplay('none')
        removeMessage(null)
    },2500)
    return ()=> clearTimeout(timeout)
  },[message])

  return (
    <div className='message' style={{display : display, color : 'green', backgroundColor : '#87ff87'}}>
       <h1 className='h6'>{success}</h1>
    </div>
  )
}

export default SuccessUp;