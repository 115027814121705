import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {catchError, handleRequest, url} from '../base/index'
import axios from 'axios'


// creating intial state
const intialState = {
    user: null,
    userInfoError : null,
    userInfoLoading: true,

    total_item: 0,
    notification : 0,
    app_reviews : null,
    is_reviewd : true,
    avg_rating : 5,
    total_reviews : 53,
    total_kirana : 10,
    total_customer : 1000,
    total_order : 1034,
    total_product : 3000,
    default_dukan : null,
    dukan_name : null,
    is_dukandar : false,


    user_dropdown : false,
    search_dropdown : false,
    side_offcanvas : false,
}

const baseUrl =  `${url}/api/user`


// creating api for the User
const userInfo = createAsyncThunk('userInfo', async (_, { rejectWithValue }) => {
    try {
        const token = JSON.parse(localStorage.getItem('token'));
        const request = await axios.get(`${baseUrl}/userInfo/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const basicInfo = createAsyncThunk('basicInfo', async (_, { rejectWithValue }) => {
    try {
        const request = await axios.get(`${baseUrl}/basicInfo/`)
        return request.data.error_message ? rejectWithValue(request.data.error_message) : request.data
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})


const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: intialState,
    reducers: {
        dropdown(state, action) {
            if (action.payload.type === 'user_dropdown' )
               state.user_dropdown = action.payload.value 
            else if(action.payload.type === 'search_dropdown')
                state.search_dropdown = action.payload.value 
            else if(action.payload.type === 'side_offcanvas')
                state.side_offcanvas = action.payload.value
            else if(action.payload === false) {
                state.user_dropdown = false
                state.search_dropdown = false
                state.side_offcanvas = false
            }
            
            
        },
        userInfoDefault(state, action) {
            state.user = null
            state.userInfoError = null
        },
        userInfoChange(state,action) {
            state.user = action.payload
        },
        notificationChange(state, action) {
            state.notification = action.payload?.data ? action.payload.data : 0
        },
        changeTotalItem(state, action) {
            action.payload.type === 'specific' ? 
            state.total_item = action.payload.total_item :
            action.payload === 'inc' ?
            state.total_item = state.total_item + 1 :
            action.payload === 'set0' ?
            state.total_item = 0 :
            state.total_item = state.total_item - 1
        },
        reviewsUpdate(state, action) {
            if(action.payload.type === 'get') {
                state.app_reviews = action.payload.data
            }
            else if(action.payload.type === 'onlyreviewd') {
                state.is_reviewd = action.payload.is_reviewd
            }
            else {
                state.is_reviewd = true
                state.app_reviews === null ? 
                    state.app_reviews = [action.payload.data] :
                    state.app_reviews = [action.payload.data , ...state.app_reviews]
                state.avg_rating = Number((((state.avg_rating * state.total_reviews) + Number(action.payload.rating) ) / (state.total_reviews + 1)).toFixed(1))
                state.total_reviews = state.total_reviews + 1
            }
        },
        changeDefaultDukan(state, action) {
            state.default_dukan = action.payload.id
            state.dukan_name = action.payload.name
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userInfo.pending, (state) => {
                state.userInfoLoading = true
                state.userInfoError = null
            })
            .addCase(userInfo.fulfilled, (state, action) => {
                state.userInfoLoading = false
                state.user = action.payload.user
                state.total_item = action.payload.total_item
                state.notification = action.payload.notification
                state.total_reviews = action.payload.total_reviews
                state.avg_rating = action.payload.avg_rating
                state.total_customer = action.payload.total_customer
                state.total_order = action.payload.total_order
                state.total_product = action.payload.total_product
                state.total_kirana = action.payload.total_dukan
                state.default_dukan = action.payload.default_dukan
                state.dukan_name = action.payload.dukan_name  
                state.is_dukandar = action.payload.is_dukandar          
            })
            .addCase(userInfo.rejected, (state, action) => {
                state.userInfoError = action.payload.error_message
            }) // end 
            .addCase(basicInfo.pending, (state)=>{
                state.userInfoLoading = true 
            })
            .addCase(basicInfo.fulfilled, (state, action) => {
                state.userInfoLoading = false
                state.notification = action.payload.notification
                state.total_reviews = action.payload.total_reviews
                state.avg_rating = action.payload.avg_rating
                state.total_customer = action.payload.total_customer
                state.total_order = action.payload.total_order
                state.total_product = action.payload.total_product
                state.total_kirana = action.payload.total_dukan
            })
            .addCase(basicInfo.rejected, (state, action) => {
                state.userInfoLoading = false
            }) // end
    }
})

export default userInfoSlice;
export { userInfo, basicInfo }
export const { userInfoDefault, userInfoChange, changeTotalItem, notificationChange, reviewsUpdate, dropdown, changeDefaultDukan } = userInfoSlice.actions
