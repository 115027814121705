import axios from "axios";
import React, { useEffect, useState } from "react";
import { ErrorUp, SuccessUp, catchError, errorName, getToken, url, CustomWeightIcon, CrossIcon, DiscountIcon, fallbackImg } from "../base";
import { useDispatch } from "react-redux";
import { changeTotalItem } from "../redux/UserInfoSlice";
import { changeTotalPrice, setCartError } from "../redux/CartSlice";
import { Link } from 'react-router-dom'

const CartItem = React.memo(({ item }) => {
    const [quantity, setQuantity] = useState(0)
    const [price, setPrice] = useState(0)
    const [removed, setRemoved] = useState(false)
    const [custom, setCustom] = useState(false)
    const [customQuantity, setCustomQuantity] = useState('')
    const [updating, setUpdating] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [imgSrc, setImgSrc] = useState(fallbackImg)
    const dispatch = useDispatch()

    async function updateCartItem(item, type) {
        if (type === 'update' && customQuantity.trim() === '') {
            setError('Enter your custom quantity !')
        }
        else {
            try {
                setCustom(false)
                if (type === 'inc' || (type === 'dec' && quantity > 1) || type === 'update') {
                    setUpdating(true)
                    setError(null)
                    setSuccess(null)
                    const token = getToken()
                    const request = await axios.put(`${url}/api/cartItem/?item=${item.id}&type=${type}&order=${item.order}&custom_quantity=${encodeURIComponent(customQuantity)}`, {}, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    if (request.data.error_message) {
                        request.data.error_message === errorName.internalError ? dispatch(setCartError(request.data.error_message)) :
                            setError(request.data.error_message)
                    }
                    else if (request.data.success_message) {
                        setSuccess(request.data.success_message)
                        if (type === 'inc') {
                            setQuantity((prev) => {
                                return prev + 1
                            })
                            setPrice(request.data.price)
                            dispatch(changeTotalPrice({ 'type': 'inc', 'amount': request.data.total_price }))
                        }
                        else if (type === 'dec' && quantity > 1) {
                            setQuantity((prev) => {
                                return prev - 1
                            })
                            setPrice(request.data.price)
                            dispatch(changeTotalPrice({ 'type': 'dec', 'amount': request.data.total_price }))
                        }
                    }
                    const time = setTimeout(() => {
                        setUpdating && setUpdating(false)
                    }, [1000])
                    return () => clearTimeout(time)
                }
            }
            catch (error) {
                dispatch(setCartError(catchError(error).error_message))
                setUpdating(false)
            }
        }
    }

    async function deleteCartItem(item) {
        try {
            setUpdating(true)
            setError(null)
            setSuccess(null)
            const token = getToken()
            const request = await axios.delete(`${url}/api/cartItem/?item=${item.id}&order=${item.order}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (request.data.error_message) {
                request.data.error_message === errorName.internalError ? dispatch(setCartError(request.data.error_message)) :
                    setError(request.data.error_message)
            }
            else if (request.data.success_message) {
                dispatch(changeTotalItem('dec'))
                dispatch(changeTotalPrice({ 'type': 'del', 'amount': price }))
                setSuccess(request.data.success_message)
                setRemoved(true)
            }
            const time = setTimeout(() => {
                setUpdating && setUpdating(false)
            }, [1000])
            return () => clearTimeout(time)
        }
        catch (error) {
            dispatch(setCartError(catchError(error).error_message))
            setUpdating(false)
        }
    }

    useEffect(() => {
        setQuantity(item.quantity)
        setPrice(item.quantity * item.product_price)
        setCustomQuantity(item.custom_quantity)
    }, [item])


    return (
        <>
            {
                removed === false ?
                    <div className="col-sm-10 offset-sm-1 offset-md-0 col-md-6 col-lg-4">
                        <div className="mcartitem">
                            <img src={imgSrc} onLoad={(e) => setImgSrc(item.image)} alt={`${item.product_name} img`} className="img-fluid" style={imgSrc === fallbackImg ? { opacity: '0.6' } : { opacity: 1 }} />
                            <div className="mcartitemdetail mt-2 mb-3 ">
                                <Link to={`/product/${item.product_name.replace(/\s+/g, "-")}/`} className="h6 text-center">{item.product_name}</Link>
                                <div className=" " style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                    <button className="secondry-button plus h6 m-0" onClick={() => updateCartItem(item, 'inc')} disabled={updating ? true : false}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" /></svg></button>
                                    <h2 className="h6">{quantity} </h2>
                                    <button className="secondry-button minus h6 m-0" onClick={() => updateCartItem(item, 'dec')} disabled={updating ? true : false}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" /></svg></button>
                                    <h2 className="h6">{price} Rs. </h2>
                                    <button className="delete h5" onClick={() => deleteCartItem(item)} disabled={updating ? true : false}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg></button>
                                    {
                                        item.loose && <span onClick={() => setCustom(!custom)} className='position-absolute' style={{ right: '10px', top: '10px', cursor: 'pointer', zIndex: 3 }}> <CustomWeightIcon style={{ fill: 'goldenrod' }} /> </span>
                                    }
                                </div>
                            </div>
                            {
                                item.offer ? <>
                                    <div className='discounticon'> <DiscountIcon className=" fa-solid fa-layer-group fa-fade h6" /></div>
                                    <div className='offer'>
                                        <div>{item.offer}</div>
                                    </div>
                                </> : <></>
                            }
                            <div className={custom ? 'customWeight show' : 'customWeight'}>
                                <div className='hindi text-center' style={{ fontSize: "15px", color: 'blue', textDecoration: 'underline' }}>Custom Quantity</div>
                                <div className='hindi text-center'>प्रोडक्ट जिस भी मात्रा में उपलब्ध है, </div>
                                <div className='hindi'>
                                    यदि उससे अधिक मात्रा में अपने हिसाब से लेना हो, जैसे <span>4kg 360gm </span> तो -
                                    निचे quantity में <span>4</span> डाल कर <span>Add To Cart </span> करे, और <span> 360gm </span> यहाँ <span>अपडेट</span> करे  <br />
                                    या उससे <span>कम मात्रा</span> में अपने हिसाब से लेना हो जैसे सिर्फ <span>50gm, 100gm, 350gm, 730gm </span> तो यहाँ अपडेट करे
                                </div>
                                <div className='d-flex'>
                                    <input className='w-75' type='text' placeholder='50gm, 200gm, 340gm, 590gm, 750gm, 900gm' value={customQuantity} onChange={(e) => setCustomQuantity(e.target.value)} />
                                    <button className='w-25 secondry-button pt-1 pb-1' style={{ width: 'fit-content' }} onClick={() => updateCartItem(item, 'update')} disabled={updating ? true : false}>Update</button>
                                </div>
                                <span onClick={() => setCustom(!custom)}><CrossIcon className='crossIcon' /></span>
                            </div>
                        </div>

                        {error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>}
                    </div> : <></>
            }
        </>
    )

})

export default CartItem;