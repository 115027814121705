import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dropdown } from '../../redux/UserInfoSlice'
import { Helmet } from 'react-helmet'
import { Loading, errorName, getToken, NoConnection, NoData, NotAuthorized, url, message, catchError, ErrorUp, SuccessUp } from '../../base'
import { SingleOrder } from '../../components'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import SingleCustomer from '../../components/SingleCustomer'
import { addNewOrder } from '../../redux/DukandarSlice'

function CustomerDetailNOrder() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [orders, setOrders] = useState(null)
    const [remaining, setRemaining] = useState(0)
    const [customerDetails, setCustomerDetails] = useState(null)
    const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
    const is_dukandar = useSelector((state) => state.userInfo.is_dukandar)
    const { id } = useParams()
    const path = window.location.pathname


    async function getCustomerAllOrder() {
        setLoading(true)
        setError(null)
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/dukandar/customer/${id}/orders/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(request.data)
            if (request.data && request.data.error_message) {
                setError(request.data.error_message)
                setCustomerDetails(request.data.customer_details)
            }
            else {
                setOrders(request.data.data)
                setCustomerDetails(request.data.customer_details)
            }
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setError(catchError(error).error_message)
            setLoading(false)
        }
    }

    async function customerNewOrder() {
        setSuccess(null)
        setError(null)
        try {
            const token = getToken()
            const request = await axios.post(`${url}/api/dukandar/customer/${id}/orders/`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (request.data && request.data.error_message)
                setError(request.data.error_message)
            else {
                console.log(request.data)
                orders ? setOrders((prev) => {
                    return [
                        request.data.data,
                        ...prev
                    ]
                }) :
                    setOrders([request.data.data])
                dispatch(addNewOrder(request.data.data))
            }
        }
        catch (error) {
            setError(catchError(error).error_message)
        }
    }

    useEffect(() => {
        dispatch(dropdown(false))
    }, [dispatch])

    useEffect(() => {
        getCustomerAllOrder()
    }, [id])

    //     useEffect(()=>{
    //       if(orders){ 
    //         console.log(orders)
    //         orders.map((item) =>{
    //          setRemaining((prev) => {
    //             console.log('---------------', Number(item.total_price), Number(item.additional_total),Number(item.discount) , Number(item.amount_recived))
    //             return prev + Number(item.total_price) + Number(item.additional_total) - Number(item.discount) - Number(item.amount_recived)
    //          })
    //       }) 
    // }
    //     },[orders]) 

    console.log('details', customerDetails)
    return (
        <section>
            <Helmet>
                <title>Customer Orders</title>
            </Helmet>

            {
                userInfoLoading || loading ? <Loading /> :
                    error === errorName["404Error"] || error === errorName.internalError ? <NoConnection /> :
                        error === errorName.networkError ? <NoConnection network='true' /> :
                            error === errorName.authenticationError ? <NotAuthorized path={path} /> :
                                error === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                                    <div className='container'>
                                        <h4 className='h4 heading mt-2'>Customer Details</h4>
                                        {customerDetails ?
                                            <>
                                                <SingleCustomer customer={customerDetails} key='1' />
                                                <div className='h6 mb-0'>New Day, New Entry ! <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => customerNewOrder()}>Create New Bill</span></div>
                                                {
                                                    orders !== null ?
                                                        <div className='order'>
                                                            <h1 className='heading h4 mt-2 mb-2'>Customer Orders!</h1>
                                                            {/* <div className='mb-2'>Total Remaining : {remaining}</div> */}
                                                            {
                                                                orders.map((item, key) => {
                                                                    return (
                                                                        <SingleOrder item={item} key={key} setPerror={setError} is_dukandar={is_dukandar} setCustomerDetailNOrder={setOrders} />
                                                                    )
                                                                })
                                                            }
                                                            {/* <div className='d-flex mt-4' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                        {
                                                            isMoreLoading ? <Sloading color={'red'} width={'50px'} height={'fit-content'} /> :
                                                                ordersLastPage === false ?
                                                                    <button className='h6 secondry-button ps-2 pe-2' style={{ width: '150px' }} onClick={() => load()}>Load more</button> : <></>
                                                        }
                                                    </div> */}
                                                        </div> : <NoData message={'Oops! No order found for this customer.'} />
                                                }
                                            </> : <NoData message='Oops! Customer not found in your dukan.' />}
                                        {
                                            error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
                                        }
                                    </div>
            }
        </section>
    )
}

export default CustomerDetailNOrder